<template>
    <a-modal
        :visible="editNote.visible"
        @cancel="onClose"
        @ok="onClose"
        :footer="null"
        :bodyStyle="{padding: '30px'}"
        width="700px"
        :zIndex="1001"
        centered
        >
        <BHLoading :show="loading" />
        <h5 class="mb-4">Edit Note</h5>

        <div>
            <a-form-model ref="ruleForm" :model="note" :rules="rules">
                <a-row>
                    <a-col :span="24">
                        <a-form-model-item label="Subject" prop="subject">
                            <a-input v-model="note.subject" size="large" placeholder="Subject" />
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24">
                        <a-form-model-item label="Note" prop="content">
                            <a-textarea
                                v-model="note.content"
                                :auto-size="{ minRows: 6, maxRows: 6 }"
                                placeholder="Add note"
                                />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>

        <div style="text-align: right;">
            <a-button @click="onClose" class="mt-3 mr-3" style="width: 125px;">CANCEL</a-button>
            <a-button @click="onSubmit" style="width: 125px;" type="primary">UPDATE</a-button>
        </div>
    </a-modal>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import searchContact from '@/components/common/searchContact'

export default {
    components:{
        BHLoading,searchContact
    },
    data() {
        return {
            note:{
                subject:'',
                content:'',
            },
            type:'',
            loading:false,
            objId:'',
            noteId:'',
            rules: {
                subject: [
                    { required: true, message: 'Please input a subject', trigger: 'blur' },
                ],
            },
        }
    },
    watch:{
        editNote:{
            handler(val){
                if (val.visible){
                    console.log('EDITNOTE VISIBLE WATCH RAN', val)
                    this.note = {
                        subject: val.note.subject,
                        content: val.note.content
                    }
                    this.objId = val.objId
                    this.noteId = val.note.id
                    this.type = val.type
                }
            },
            deep:true,
        }
    },
    computed:{
        instance(){
            return this.$store.state.instance
        },
        editNote(){
            return this.$store.state.contacts.editNote
        },
        contacts(){
            return Object.values(this.$store.state.contacts.allContacts)
        },
        loadingPage:{
            get(){
                return this.$store.state.appData.loadingPage
            },
            set(val){
                this.$store.commit('LOAD_PAGE',val)
            }
        },
        opportunities(){
            return this.$store.state.contacts.opportunities
        }
    },
    methods:{
        onClose(){
            console.log('ONCLOSE RAN')
			if(this.$refs['ruleForm']){
				this.$refs['ruleForm'].resetFields();
			}
            this.$store.commit('CLOSE_EDIT_NOTE')
            this.note = {
                subject:'',
                content:'',
            }
            this.noteId = ''
            this.objId = ''
            this.type = ''
        },
        onSubmit(){

            this.$refs.ruleForm.validate(async valid => {

                if (valid) {
                    console.log('CREATE RAN')
                    this.loading = true

                    let sendObj = JSON.parse(JSON.stringify(this.note))

                    console.log('sendOjb', sendObj)
                    console.log('CONTACT PUT REQUEST RAN')
                    this.$api.put(`/comments/:instance/${this.noteId}`,sendObj).then( ({data}) => {
                        console.log('DATAA COMING BACK', data)
                        data.user = data.user.id
                        let newData = {
                            id:this.objId,
                            notes:data
                        }
                        if (this.type == 'contact'){
                            this.$store.commit('UPDATE_CONTACT_NOTE',newData)
                        } else {
                            this.$store.commit('UPDATE_OPPORTUNITY_NOTE',newData)
                        }

                        this.loading = false
                        this.onClose()
                    }).catch( err => {
                        console.log('ERORR', err)
                        if (err.response.data.error == 'Not Found'){
                            this.$message.error('Not Found!')
                        }
                        this.loading = false
                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
    },
    created(){

    },
    mounted(){

    }
}
</script>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
</style>

<style lang="scss" scoped>
    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
</style>
