<template>
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll; background-color: #F7F5F9;">
        <contactDetails />
        <opportunityDetails />
        <h4 v-if="!tasks.length" class="mb-3 mt-3">Tasks</h4>
        <template v-if="tasks.length && !showExpandDetails && !showExpandOpportunity">
            <ActiveTable @contactdetails="contactDetails" />
        </template>

        <template v-if="!tasks.length">
            <EmptyApp :text="'You don\'t have any tasks'" :img="require('@/assets/emptyIcons/tasks.png')" />
        </template>

        <!-- <ExpandedDetails @closeexpand="closeExpandDetails" /> -->
        <!-- <ExpandedOpportunityDetails @closeexpand="closeExpandOpportunity" /> -->
        
    </div>
</template>

<script>
    import BHLoading from 'bh-mod/components/common/Loading'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    import ActiveTable from '@/components/contacts/ActiveTable'
    import contactDetails from '@/components/common/contactDetails'
    import opportunityDetails from '@/components/common/opportunityDetails'
    import moment from 'moment'
    import ExpandedDetails from '@/components/common/ExpandedDetails'
    import ExpandedOpportunityDetails from '@/components/common/ExpandedOpportunityDetails'

    export default {
        components:{
            BHLoading,EmptyApp,ActiveTable,contactDetails,opportunityDetails,ExpandedDetails,ExpandedOpportunityDetails
        },
        data() {
            return {
                test:0,
            }
        },
        watch:{
            
        },
        computed:{
            externalContacts(){
                return this.$store.state.contacts.externalContacts
            },
            showExpandDetails(){
                return this.$store.state.contacts.expandDetails.visible
            },
            expandDetails(){
                return this.$store.state.contacts.expandDetails
            },
            showExpandOpportunity(){
                return this.$store.state.contacts.expandOpportunity.visible
            },
            expandOpportunity(){
                let expand = this.$store.state.contacts.expandOpportunity
                expand.opportunity.notes.sort((a,b) => {
                    return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                })
                return expand
            },
            tags(){
                console.log('TAGSSSSS OBJECT VALUES', this.$store.state.contacts.tags)
                return this.$store.state.contacts.tags
            },
            users(){
                return this.$store.state.contacts.users
            },
            sidebarSelection(){
                return this.$store.state.sidebarSelection
            },
            instance(){
                return this.$store.state.instance
            },
            contacts(){
                console.log('all contacts', this.$store.state.contacts.allContacts)
                return this.$store.state.contacts.allContacts
            },
            tasks(){
                return this.$store.state.contacts.tasks
            },
            
        },
        methods:{
            moment,
            closeExpandDetails(obj){
                console.log('CLOSE EXPAND DETAILS RAN', obj)
                obj.tags.forEach( x => {
                    console.log('Tag', x)
                    if (!this.tags.hasOwnProperty(x.id)){
                        this.$store.commit('UPDATE_TAGS',x)
                    }
                })
                this.$store.commit('CLOSE_EXPAND_DETAILS')
                this.$store.commit('SET_PROP', {where:['allContacts',obj.id],what:obj})
                this.$store.commit('UPDATE_ALL_OPPORTUNITIES', obj)
                this.$store.commit('UPDATE_ALL_TASKS', obj)
            },
            closeExpandOpportunity(obj){
                console.log('CLOSE EXPAND OPPORTUNITY RAN', obj)
                this.$store.commit('CLOSE_EXPAND_OPPORTUNITY')
                this.$store.commit('UPDATE_ALL_TASKS', obj)
            },
            contactDetails(obj){
                console.log('OPENING CONTACT DETAILS', obj)
                this.$store.commit('OPEN_CONTACT_DETAILS',obj)
            },
        },
        mounted(){

        },
        created() {
            this.$store.commit('SIDEBAR_SELECTION', {value:'Active'})

            // $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
            
        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>

    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:5%;
        }
    }
    .aB{
        align-items: baseline;
    }

</style>