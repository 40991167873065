<template>
  <div class="editor">
    <editor-menu-bubble :editor="editor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, menu }">
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
            <i class="fe fe-bold" />
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
            <i class="fe fe-italic" />
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
        >
            <i class="fe fe-code" />
        </button>
        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <i class="fe fe-underline" />
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <i class="fe fe-list" />
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <a-icon type="ordered-list" />
        </button>

      </div>
    </editor-menu-bubble>

    <editor-content @update="editDescription" class="editor__content" :editor="editor" />
    <a-button @click="editDescription" class="mt-3" type="primary" v-if="editButton">UPDATE DESCRIPTION</a-button>
  </div>
</template>

<script>

import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'
export default {
    props:{
        text:{
            default:'',
            type:String
        }
    },
  components: {
    EditorContent,
    EditorMenuBubble,

  },
  computed:{
      editTask(){
        return this.$store.state.contacts.editTask.task
      }
  },
  data() {
    return {
      keepInBounds: true,
      editor: null,
      editButton:false,
    }
  },
  methods:{
		editDescription() {
			let sendObj = JSON.parse(JSON.stringify(this.editTask))
			sendObj.description = this.editor.view.dom.innerHTML
			this.$api.put(`/tasks/:instance/${sendObj.id}`, sendObj).then(({ data }) => {
				console.log('update task', data)
				this.$store.commit('UPDATE_TASK', data)
				this.$emit('update')
				this.editButton = false
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		}
  },
  created(){
      this.editor = new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        onUpdate: ({getHTML}) => {
            this.editButton = true
        },
        onBlur:() =>{

            // if (this.updatedHTML == null) {
            //     this.updatedHTML = ''
            // }
            // console.log('HTML', this.updatedHTML)
            // this.$emit('save', this.updatedHTML)
        },
        content: `
          <div>
            ${this.text}
          </div>

        `,
      })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
