<template>
    <a-drawer
        :zIndex="999"
        :width="'500px'"
		:wrapClassName="'hide-scroll'"
        @close="onClose"
        :visible="contactDetails.show"
        :drawerStyle="{ height: '100%', overflow: 'auto' }"
        >
        <bhLoading :show="loading" />

        <div v-if="contactDetails.contact">
            <!-- <bh-loading :show="loading" /> -->
            <div @click="expandDetails(contact)" style="width: 56px; height: 56px; position: absolute; right: 56px; top: 4px; font-size: 15px; cursor: pointer; text-align: center; z-index: 1001;">
                <a-icon type="arrows-alt" style="line-height: 56px;" />
            </div>

            <!-- Header -->
            <!-- <vue-custom-scrollbar class="drawerScroll" style="max-height:95vh; min-height:95vh; width: 100%;"> -->
                <div v-if="contact.hasOwnProperty('firstName') && contact.hasOwnProperty('lastName')" class="w-full" style="text-align: center;">
                    <a-avatar style="font-size: 40px; background-color:#1070CA" shape="circle" :size="100">{{ getInitial(contact) || contact.email[0].toUpperCase()}}</a-avatar>
                    <h5 class="mt-4" style="margin-bottom: 0;">{{contact.firstName && contact.lastName && contact.firstName != '' && contact.lastName != '' ? contact.firstName + ' ' + contact.lastName:contact.email}}</h5>
                    <div style="color: #9EA0A5;">{{contact.jobTitle}}</div>
                    <div class="mt-3" style="font-size:18px; color: var(--orange);">{{displayPhone(contact.phone)}}</div>
					<a-progress class="w-1/2" status="normal" :percent="+((contact.leadScore/$store.state.contacts.maxLeadScore) * 100).toFixed(2)" size="small" strokeColor="#f7941e">
						<template #format="percent">
							<span style="font-size: medium;">
								{{ contact.leadScore }}
							</span>
						</template>
					</a-progress>

                    <!-- Action buttons -->
                    <div class="w-1/2 mt-3 dF" style="margin-left: auto; margin-right: auto; justify-content: space-between; align-items: center;">
                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">Edit Lead</template>
                            <div @click="editContact(contact.id)" class="edit-delete">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                            </div>
                        </a-tooltip>
                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">Email Lead</template>
                            <div class="edit-delete">
                                <svg @click="sendEmail(contact)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                            </div>
                        </a-tooltip>
                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">Delete Lead</template>
                            <div @click="deleteContact(contact)" class="edit-delete">
                                <svg style="color:var(--danger)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                            </div>
                        </a-tooltip>
                    </div>
                </div>


                <!-- Body -->
                <a-tabs class="contact-tabs mt-3" default-active-key="1">

                    <!-- Tab One -->
                    <a-tab-pane key="1" tab="Activity">
                        <div class="tab-content">
                            <a-row :gutter="16" style="text-align: center;">
                                <a-col :span="12">
                                    <div style="font-size: 20px; font-weight: bold;">{{contact.updatedAt ? getInactive(contact.updatedAt) : '0'}}</div>
                                    <div>Inactive Days</div>
                                </a-col>
                                <a-col :span="12">
                                    <div style="font-size: 20px; font-weight: bold;">{{contact.updatedAt ? convertDate(contact.updatedAt) : '0'}}</div>
                                    <div>Last Updated Date</div>
                                </a-col>
                            </a-row>




                            <a-button @click="convertLead" class="mt-5" size="large" style="width:100%" type="primary">CONVERT LEAD</a-button>
                            <div class="w-full mt-5">
                                <a-collapse class="collapse-display collapse-icon">
                                    <template #expandIcon="props">
                                        <a-icon type="down" :rotate="props.isActive ? -180 : 0" />
                                    </template>
                                    <!-- Opportunity Collapse -->
                                    <a-collapse-panel >
                                        <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                            <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Opportunities (${opportunities.length})`}}</div>
                                                <div @click="addNew('Opportunity')" style="color:var(--orange)" class="dF aC"><a-icon class="mr-2" type="plus" />Add</div>
                                            </div>
                                        </div>
                                        <div v-if="opportunities.length">
                                            <div :class="oppI == 0 ? '':'mt-4'" class="dF jSB aT" v-for="(opp, oppI) in opportunities" :key="opp.id" :value="opp.id">
                                                <div class="dF">
                                                    <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" width="15" height="15" fill="#F79425" data-icon="money-bill-alt" class="svg-inline--fa fa-money-bill-alt fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path  d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 192c-53.02 0-96-50.15-96-112 0-61.86 42.98-112 96-112s96 50.14 96 112c0 61.87-43 112-96 112zm272 32h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z"></path></svg>
                                                    </div>
                                                    <div>
                                                        <div style="color:var(--orange); font-size:15px">{{opp.name}}</div>
                                                        <div class="dF aC"><div style="color:#40454C">{{getStage(opp.stage)}}</div>&nbsp;<div>|</div>&nbsp;<div style="color:black">{{`$${opp.dealValue}`}}</div></div>
                                                        <div style="color:#707070">{{`Estimated Opportunity Close Date: ${convertDate(opp.closeDate)}`}}</div>
                                                    </div>
                                                </div>
                                                <div class="dF">
                                                    <div class="dF mr-4">
                                                        <template v-for="(user,userI) in opp.owners">
                                                            <div v-if="userI < 3" :key="userI" class="owner-icon aaa" :style="userI !== 0? 'margin-left: -5px' : ''" style="cursor:pointer">
                                                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                                    <template slot="title">{{`${user.firstName} ${user.lastName}`}}</template>
                                                                    <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="20"/>
                                                                    <a-avatar v-else>{{getInitial(user)}}</a-avatar>
                                                                </a-tooltip>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <div class="dF">
                                                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                            <template slot="title">Edit Opportunity</template>
                                                            <svg @click="edit('opportunity', opp.id)" style="color:#9EA0A5; cursor:pointer" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2 action-button"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                        </a-tooltip>
                                                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                            <template slot="title">Delete Opportunity</template>
                                                            <svg @click="deleteItem('opportunity', opp.id)" style="color:var(--danger); cursor:pointer" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                        </a-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>This lead has no opportunities...</div>
                                    </a-collapse-panel>

                                    <!-- Note Collapse -->
                                    <a-collapse-panel >
                                        <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                            <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Notes (${contact.notes && contact.notes.length ? contact.notes.length : '0'})`}}</div>
                                                <div @click="addNew('Note')" style="color:var(--orange)" class="dF aC"><a-icon class="mr-2" type="plus" />Add</div>
                                            </div>
                                        </div>
                                        <template v-if="contact.notes && contact.notes.length">
                                            <div :class="noteI == 0 ? '':'mt-4'" class="dF jSB" :gutter="16" v-for="(note,noteI) in contact.notes" :key="note+noteI">
                                                <div class="dF">
                                                    <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                                        <svg width="12" height="12" viewBox="0 0 20 20" fill="#F79425"><path id="Icon_material-comment" data-name="Icon material-comment" d="M22.99,5A2,2,0,0,0,21,3H5A2.006,2.006,0,0,0,3,5V17a2.006,2.006,0,0,0,2,2H19l4,4ZM19,15H7V13H19Zm0-3H7V10H19Zm0-3H7V7H19Z" transform="translate(-3 -3)"/> </svg>
                                                    </div>
                                                    <div>
                                                        <div style="color:var(--orange); font-size:15px">{{`Subject: ${note.subject}`}}</div>
                                                        <div style="color: #9EA0A5; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 245px;">{{note.content}}</div>
                                                        <div style="color: #9EA0A5;">{{moment(note.updatedAt).fromNow()}}</div>
                                                    </div>
                                                </div>
                                                <div class="dF">
                                                    <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                        <template slot="title">Edit Note</template>
                                                        <svg @click="edit('note', note.id)" style="cursor:pointer; color:#9EA0A5" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2 action-button"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                    </a-tooltip>
                                                    <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                        <template slot="title">Delete Note</template>
                                                        <svg @click="deleteItem('note', note.id)" style="cursor:pointer; color:var(--danger)" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                    </a-tooltip>
                                                </div>
                                            </div>
                                        </template>
                                        <div v-else>This lead has no notes...</div>
                                    </a-collapse-panel>

                                    <!-- Task Collapse -->
                                    <a-collapse-panel >
                                        <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                            <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Tasks (${tasks.length})`}}</div>
                                                <div @click="addNew('Task')" style="color:var(--orange)" class="dF aC"><a-icon class="mr-2" type="plus" />Add</div>
                                            </div>
                                        </div>
                                        <template v-if="tasks.length">
                                            <div :class="taskI == 0 ? '':'mt-4'" v-for="(task,taskI) in tasks" :key="task+taskI">
                                                <template v-if="task.relatedType === 'contact'">
                                                    <div class='dF jSB' v-if="task.contact.id === contact.id">
                                                        <div class="dF">
                                                            <!-- <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="#F79425" stroke="#F79425" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                                                            </div> -->
                                                            <a-checkbox :checked="task.completed" @change="(e) => checkComplete(e, task.id)" style="width:30px; height:30px" class="checkbox-circle big-checkbox-circle mr-3" />
                                                            <div>
                                                                <div style="color:var(--orange); font-size:15px">{{task.title}}</div>
                                                                <div class="taskParagraph" style="color: #000; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 165px;" v-html="task.description"></div>
                                                                <div v-if="task.dueDate !== 0" style="color:#9EA0A5;">{{'Due Date: ' + convertDate(task.dueDate)}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="dF jE mb-3">
                                                            <template v-for="(user,userI) in task.assignTo">
                                                                <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''" style="cursor:pointer">
                                                                    <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                                        <template slot="title">{{`${user.firstName} ${user.lastName}`}}</template>
                                                                        <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="20"/>
                                                                        <a-avatar v-else style="font-size: 16px;" shape="circle" :size="30">{{getInitial(user)}}</a-avatar>
                                                                    </a-tooltip>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                        <div v-else>This lead has no tasks...</div>
                                    </a-collapse-panel>

                                    <!-- Appointment Collapse -->
                                    <a-collapse-panel >
                                        <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                            <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Appointments (${appointments.length})`}}</div>
                                                <div @click="addNew('Appointment')" style="color:var(--orange)" class="dF aC"><a-icon class="mr-2" type="plus" />Add</div>
                                            </div>
                                        </div>
                                        <template v-if="appointments.length">
                                            <div :class="appointmentI == 0 ? '' : 'mt-4'" v-for="(appointment,appointmentI) in appointments" :key="appointment+appointmentI">
                                                <template v-if="appointment.relatedType === 'contact'">
                                                    <div class="dF jSB" v-if="appointment.contact.id === contact.id">
                                                        <div class="dF">
                                                            <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#F79425" stroke="#FFF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                                            </div>
                                                            <div>
                                                                <div style="color:var(--orange); font-size:15px">{{appointment.title}}</div>
                                                                <div style="color: #9EA0A5;">{{convertDate(appointment.date) + ' | ' + moment(appointment.startTime).format('LT') + ' to ' + moment(appointment.endTime).format('LT')}}</div>
                                                                <div class="dF">
                                                                    <div>{{contact.firstName && contact.lastName && contact.firstName != '' && contact.lastName != '' ? `${contact.firstName} ${contact.lastName},` : ''}}</div>&nbsp;
                                                                    <div class="dF" v-for="(user,userI) in appointment.assignTo" :key="user+userI">
                                                                        <div v-if="user.id === currentUser.user.id">You</div>
                                                                        <div v-else>{{user.firstName + ' ' + user.lastName}}</div>

                                                                        <div v-if="userI !== appointment.assignTo.length-1">,&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="dF">
                                                            <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                                <template slot="title">Edit Appointment</template>
                                                                <svg @click="edit('appointment', appointment.id)" style="cursor:pointer; color:#9EA0A5" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2 action-button"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                            </a-tooltip>
                                                            <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                                <template slot="title">Delete Appointment</template>
                                                                <svg @click="deleteItem('appointment', appointment.id)" style="cursor:pointer; color:var(--danger)" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                            </a-tooltip>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                        <div v-else>This lead has no appointments...</div>
                                    </a-collapse-panel>

									<!-- Email Collapse -->
                                    <a-collapse-panel >
										<bhLoading :show="loadingMail" />
                                        <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                            <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Email (${emailCount})`}}</div>
                                                <!-- <div @click="addNew('Note')" style="color:var(--orange)" class="dF aC"><a-icon class="mr-2" type="plus" />Add</div> -->
                                            </div>
                                        </div>
                                        <template v-if="threads && threads.length">
											<a :href="`https://contacts.bildhive.${$tld}/leads/${contact.id}?tab=email`">
											<div
												class="email-card"
												style="cursor: pointer"
												v-for="(thread, threadI) in threads"
												:value="thread.id"
												:key="thread.id"
											>
												<div
													v-if="
														thread.messages &&
														thread.messages.length &&
														thread.messages[0].subject
													"
												>
													<div class="dF jSB">
														<div class="dF aS">
															<div
																class="smallIcon mr-3"
																style="background-color: #ffdead"
															>
																<svg
																	width="12"
																	height="12"
																	viewBox="0 0 20 20"
																	fill="#F79425"
																>
																	<path
																		id="Icon_material-comment"
																		data-name="Icon material-comment"
																		d="M22.99,5A2,2,0,0,0,21,3H5A2.006,2.006,0,0,0,3,5V17a2.006,2.006,0,0,0,2,2H19l4,4ZM19,15H7V13H19Zm0-3H7V10H19Zm0-3H7V7H19Z"
																		transform="translate(-3 -3)"
																	/>
																</svg>
															</div>
															<div class="f1" style="max-width: 240px;">
																<span>
																	<span style="color: #000">{{
																		`${thread.messages[0].subject}`
																	}}</span>
																	<br />
																	<span style="color: #9ea0a5">{{
																		`from: ${getFrom(
																			thread.messages[0].from
																		)}`
																	}}</span>
																</span>
																<div class="dF aC">
																	<div style="color: #9ea0a5">
																		{{
																			`to: ${getTo(
																				thread.messages[0].to
																			)}`
																		}}
																	</div>
																	<a-popover
																		class="ml-2"
																		placement="bottom"
																	>
																		<template slot="content">
																			<div class="dF aC">
																				<div
																					style="
																						color: #9ea0a5;
																					"
																				>
																					From:
																				</div>
																				&nbsp;
																				<div
																					style="color: #000"
																				>
																					{{
																						thread
																							.messages[0]
																							.from
																					}}
																				</div>
																			</div>
																			<div class="dF aC">
																				<div
																					style="
																						color: #9ea0a5;
																					"
																				>
																					To:
																				</div>
																				&nbsp;
																				<div
																					style="color: #000"
																				>
																					{{
																						thread
																							.messages[0]
																							.to
																					}}
																				</div>
																			</div>
																		</template>
																		<a-icon
																			style="cursor: pointer"
																			type="caret-down"
																		/>
																	</a-popover>
																</div>
															</div>
														</div>
														<div style="color: #9ea0a5">
															{{ getDate(thread.messages[0].date) }}
														</div>
													</div>

													<div
														v-if="
															thread.messages[0] &&
															thread.messages[0].snippet
														"
														class="mt-3"
													>
														{{ thread.messages[0].snippet }}
													</div>
												</div>
												<hr
													v-if="threadI != threads.length - 1"
													style="margin: 0"
												/>
											</div>
											</a>
										</template>
                                        <div v-else>This lead doesn't have any email...</div>
                                    </a-collapse-panel>

									<!-- Transactions Collapse -->
                                    <a-collapse-panel >
                                        <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                            <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Transactions (${transactions.length})`}}</div>
                                                <!-- <div @click="addNew('Note')" style="color:var(--orange)" class="dF aC"><a-icon class="mr-2" type="plus" />Add</div> -->
                                            </div>
                                        </div>
                                        <template v-if="transactions && transactions.length">
											<div class="dF jSB aS list-transactions" :class="transI == 0 ? '' : 'mt-4'" v-for="(trans,transI) in transactions" :key="trans.id" :value="trans.id">
												<div class="dF aS">
													<div>
														<div style="color:black; font-size:15px">{{transactionName(trans)}}</div>
														<div style="color:black">{{`ID: ${trans.id}`}}</div>
														<div style="color:var(--orange)">{{`Purchaser(s): ${transactionPurchasers(trans)}`}}</div>
														<div style="color:#9EA0A5">{{transactionDate(trans.createdAt)}}</div>
													</div>
												</div>
												<div class="dF aC">
													<i style="font-size:18px" class="fa fa-user mr-2" />
													<div class="mr-3" v-if="trans.purchasers && trans.purchasers.length">{{trans.purchasers.length}}</div>
													<div class="mr-3" v-for="(user,userI) in trans.owners" :key="user.id" :value="user.id">
														<div v-if="userI < 3" :key="userI" class="owner-icon dF aC jC" :style="userI !== 0? 'margin-left: -5px' : ''" style="cursor:pointer;">
															<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
																<template slot="title">{{`${user.firstName} ${user.lastName}`}}</template>
																<a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="20"/>
																<a-avatar :size="20" v-else>{{getInitial(user)}}</a-avatar>
															</a-tooltip>
														</div>
													</div>
													<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
														<template slot="title">View Transaction</template>
														<a target="_blank" :href="`https://transactions.bildhive.${$tld}/transaction/${trans.id}`">
															<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
														</a>
													</a-tooltip>
												</div>
											</div>
										</template>
                                        <div v-else>This lead doesn't have any transactions...</div>
                                    </a-collapse-panel>

                                    <!-- Form Collapse -->
                                    <a-collapse-panel >
                                        <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                            <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Form Submissions (${forms.length})`}}</div></div>
                                        </div>
                                        <template v-if="forms.length">
                                            <div :class="formI == 0 ? '' : 'mt-4'" class="dF jSB" v-for="(form, formI) in forms" :key="form.id" :value="form.id">
                                                <div class="dF">
                                                    <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#F79425" stroke="#F79425" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-justify"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg>
                                                    </div>
                                                    <div>
                                                        <div style="color:black; font-size:15px">{{form.form && form.form.name ? form.form.name : 'N/A'}}</div>
                                                        <div style="color:#9EA0A5">{{getDateString(form.createdAt)}}</div>
                                                        <div style="color:var(--orange)" v-if="form.contact">{{form.contact.firstName && form.contact.lastName && form.contact.firstName != '' && form.contact.lastName != '' ? `Submitted by ${form.contact.firstName} ${form.contact.lastName}` :''}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>This lead has no forms...</template>
                                    </a-collapse-panel>

                                    <!-- Attachment Collapse -->
                                    <a-collapse-panel >
                                        <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                            <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Attachments (${attachments.length})`}}</div></div>
                                        </div>
                                        <template v-if="attachments.length">
                                            <div :class="attachmentI == 0 ? '' : 'mt-4'" class="dF jSB" v-for="(attachment, attachmentI) in attachments" :key="attachmentI" :value="attachmentI">
                                                <div class="dF">
                                                    <div class="smallIcon mr-3 dF aC jC" style="background-color: #FFDEAD;">
                                                        <i style="font-size:16px; color:var(--orange)" class="fa fa-file" />
                                                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#F79425" stroke="#F79425" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-justify"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg> -->
                                                    </div>
                                                    <div>
                                                        <div style="color:var(--orange); font-size:15px">{{attachment.label}}</div>
                                                        <!-- <div style="color:#9EA0A5">{{getDateString(form.createdAt)}}</div> -->
                                                        <!-- <div style="color:var(--orange)" v-if="form.contact">{{form.contact.firstName && form.contact.lastName && form.contact.firstName != '' && form.contact.lastName != '' ? `Submitted by ${form.contact.firstName} ${form.contact.lastName}` :''}}</div> -->
                                                    </div>
                                                </div>
                                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                    <template slot="title">Download Attachment</template>
                                                    <a :href="attachment.value" target="_blank"><i style="font-size:16px;cursor:pointer" class="fe fe-download" /></a>
                                                </a-tooltip>
                                            </div>
                                        </template>
                                        <template v-else>This lead has no attachments</template>
                                    </a-collapse-panel>
                                    <a-collapse-panel >
                                        <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                            <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Timeline`}}</div>

                                            </div>
                                        </div>
                                        <div v-for="(date,dateI) in createDates" :key="date+dateI">
                                            <h6 class="mb-4">{{textDate(date.date)}}</h6>
                                            <a-timeline>
                                                <div class="dF" v-for="(d,i) in date.sorted" :key="d+i">
                                                    <div class="mr-3" style="margin-top: -5px; color: #9EA0A5;">
                                                        {{textTime(d)}}
                                                        <!-- {{d.date}} -->
                                                    </div>
                                                    <a-timeline-item>{{d.logNote}}</a-timeline-item>
                                                </div>
                                            </a-timeline>
                                        </div>
                                    </a-collapse-panel>
                                </a-collapse>
                            </div>
                        </div>
                    </a-tab-pane>

                    <!-- Tab Two -->
                    <a-tab-pane key="2" tab="Details">

                        <div class="tab-content">
                            <a-button @click="convertLead" class="mb-5" size="large" style="width:100%" type="primary">CONVERT LEAD</a-button>
                            <a-form-model ref="ruleForm" :model="contact" :rules="rules">
                                <a-row :gutter="16">
                                    <a-col :span="24">
                                        <a-form-model-item prop="firstName" label="First Name">
                                            <div style="color:#000">{{contact.firstName}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24">
                                        <a-form-model-item label="Last Name" prop="lastName">
                                            <div style="color:#000">{{contact.lastName}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="contact.company">
                                        <a-form-model-item label="Company" prop="company">
                                            <div style="color:#000">{{contact.company}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="contact.jobTitle">
                                        <a-form-model-item label="Job Title" prop="jobTitle">
                                            <div style="color:#000">{{contact.jobTitle}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24">
                                        <a-form-model-item label="Work Email" prop="email">
                                            <div style="color:#000">{{contact.email}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="contact.phone">
                                        <a-form-model-item label="Work Phone" prop="phone">
                                            <div style="color:#000">{{displayPhone(contact.phone)}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="contact.website">
                                        <a-form-model-item label="Company Website" prop="website">
                                            <div style="color:#000">{{contact.website}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <div v-if="contact.social && contact.social.length && contact.social[0] && contact.social[0].value">
                                        <a-col :span="24" v-for="(soc,socI) in contact.social" :key="soc+socI">
                                            <a-form-model-item v-if="contact.social[socI].value" :label="contact.social[socI].key" prop="social">
                                                <div style="color:#000">{{contact.social[socI].value}}</div>
                                            </a-form-model-item>
                                        </a-col>
                                    </div>
                                    <a-col :span="24" v-if="contact.address">
                                        <a-form-model-item label="Address" prop="social">
                                            <div style=" line-height: 25px;">{{contact.address}}</div>
                                            <div style=" line-height: 25px;">{{contact.city + ', ' + contact.region + ' ' + contact.postal}}</div>
                                            <div style=" line-height: 25px;">{{contact.country}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24">
                                        <a-form-model-item v-if="contact.visibility" label="Visibility" prop="visibility">
                                            <div style="color:#000">{{contact.visibility[0].toUpperCase() + contact.visibility.substring(1)}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24">
                                        <a-form-model-item label="Description" prop="description">
                                            <div v-if="contact.description" style="font-weight: bold;">{{contact.description}}</div>
                                            <div v-else style="color: #D0C9D6;">No description</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="contact.tags && contact.tags.length">
                                        <a-form-model-item label="Tags" prop="tags">
                                            <template v-for="(tag,tagI) in contact.tags">
                                                <a-tag style="background-color: #ECE9F1; text-align: center;" :key="tag+tagI">
                                                    <div v-if="contact.tags && contact.tags.length">{{tags[`${tag}`] ? tags[`${tag}`].name : ''}}</div>
                                                    <div v-else>None</div>
                                                </a-tag>
                                            </template>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                            </a-form-model>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            <!-- </vue-custom-scrollbar> -->
        </div>
    </a-drawer>
</template>

<script>

  import bhLoading from 'bh-mod/components/common/Loading'
  import vueCustomScrollbar from 'vue-custom-scrollbar'
  import moment from 'moment'
  import 'moment-timezone'
  import {formatDate} from 'bh-mod'

  export default {
    props:{
        time:{
            default:0,
            type:Number,
        }
    },
    components:{bhLoading,vueCustomScrollbar},
    data() {
      return {
        attachments:[],
        forms:[],
        updateTime:0,
        contact:{},
        notes:[],
		threads:[],
        loading:false,
		loadingMail:false,
        taskNum:0,
        appointmentNum:0,
		emailCount:0,
		contactId:'',

        rules: {
            firstName: [
                { required: true, trigger: 'blur' },
            ],
            lastName: [
                { required: true, trigger: 'blur' },
            ],
            email: [
                { required: true, trigger: 'blur' },
            ],
            phone: [
                { required: true, trigger: 'blur' },
            ],
        },
        showNotes:true,
        showTasks:true,
        showAppointments:true,
        showSubmissions:true,
        showTimeline:true,
      };
    },
    watch:{
        showDetails(val){
            if (val){
                this.contact = this.contactDetails.contact
				this.loading = true
				if (this.contacts[this.contact.id] || this.externalContacts[this.contact.id]) this.loading = false;
				this.$api.get(`/contacts/:instance/${this.contact.id}`).then(({ data }) => {
					this.$store.commit('OPEN_CONTACT_DETAILS', data)
					this.contact = data
					if (
						data.tags &&
						data.tags.length &&
						data.tags[0].id
					) {
						data.tags = data.tags.map((x) => (x = x.id));
					}
					this.$store.commit("ADD_EXTERNAL_CONTACT", data);
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				}).finally(() => this.loading = false)
                this.$api.get(`/form-submits/:instance?contact=${this.contact.id}`).then(({data}) => {
                    this.forms = data
                    this.forms.forEach(form => {
                        if (form.data && form.data.fields) {
                            let files = form.data.fields.filter(x => {
                                if (x.field && x.field.type && x.field.type == 'file' && x.value && x.value.includes('https')) return x
                            })
                            this.attachments = this.attachments.concat(files)
                        }
                    })
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
                if (this.allContacts[this.contact.id] && this.allContacts[this.contact.id].others && this.allContacts[this.contact.id].others.attachments) {
                    this.attachments = this.attachments.concat(this.allContacts[this.contact.id].others.attachments)
                } else if (this.externalContacts[this.contact.id] && this.externalContacts[this.contact.id].others && this.externalContacts[this.contact.id].others.attachments) {
                    this.attachments = this.attachments.concat(this.externalContacts[this.contact.id].others.attachments)
                }
                if (this.contact.notes && this.contact.notes.length && (typeof this.contact.notes[0] === 'string' || this.contact.notes[0] instanceof String)){
                    // this.loading = true
                    this.$api.get(`/contacts/:instance/${this.contact.id}/notes`).then( ({data}) => {
                        data.sort((a,b) => {
                            return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                        })
                        this.contact.notes = data
                        this.$store.commit('UPDATE_CONTACT_NOTES', this.contact)
                        // this.loading = false
                        this.notes = data
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
				this.$api.get(`/contacts/:instance/inbox-count?contact=${this.contact.email}`).then(({ data }) => {
					this.emailCount = data
					// this.loading = false
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
				this.loadingMail = true;
				this.$api.get(`/contacts/:instance/inbox?contact=${this.contact.email}`).then(({ data }) => {
                    this.threads = data;
                    this.loadingMail = false;
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
            } else {
                this.forms = []
                this.attachments = []
            }
        },
        allContacts:{
            handler(val){
                if (this.contact && this.contact.id && val[this.contact.id]) {
                    this.contact = JSON.parse(JSON.stringify(val[this.contact.id]))
                    if (this.contact.notes && this.contact.notes.length && typeof this.contact.notes != 'string'){
                        this.contact.notes.sort((a,b) => {
                            return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                        })
                    }
                }
            },
            deep:true
        },
        externalContacts:{
            handler(val){
                if (this.contact && this.contact.id && val[this.contact.id]) {
                    this.contact = JSON.parse(JSON.stringify(val[this.contact.id]))
                    if (this.contact.notes && typeof this.contact.notes != 'string'){
                        this.contact.notes.sort((a,b) => {
                            return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                        })
                    }
                }
            },
            deep:true
        }
    },
    computed:{
		timezone(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeZone && typeof this.$store.state.contacts.allSettings.user.options.regional.timeZone == 'string' ? this.$store.state.contacts.allSettings.user.options.regional.timeZone : 'America/New_York'
		},
		timeFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeFormat ? this.$store.state.contacts.allSettings.user.options.regional.timeFormat : 12
		},
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
		transactions() {
			console.log('consoling', this.id , this.contact)
			let contact = {}
			if (this.contact.id != '') {
				this.contactId = this.contact.id
				if (this.allContacts[this.contact.id]) contact = this.allContacts[this.contact.id]
				else if (this.externalContacts[this.contact.id]) contact = this.externalContacts[this.contact.id]
			} else if (this.contact.id == '' && Object.keys(this.contact).length != 0) {
				contact = this.contact
				this.contactId = this.contact.id
			}
			let transactions = []
			if (this.$store.state.contacts.transactions) {
				transactions = this.$store.state.contacts.transactions.filter(x => {
					if (x.purchasers) {
						let emails = x.purchasers.map(y => y = y.email)
						if (emails.includes(contact.email)) return x
					}
				})
			}
			return transactions
        },
        allContacts() {
            return this.$store.state.contacts.allContacts
        },
        externalContacts() {
            return this.$store.state.contacts.externalContacts
        },
        contacts() {
            return Object.values(this.$store.state.contacts.allContacts)
        },
        events(){

            let events = []
            if (this.contact && this.contact.id) {
                if (this.contact.updatedAt && this.contact.createdAt && parseInt(this.moment(this.contact.updatedAt).format('X')*1000) == parseInt(this.moment(this.contact.createdAt).format('X')*1000)) {
                    let obj = {}
                    obj.date = this.contact.createdAt
                    obj.logNote = 'This contact was added'
                    events.push(obj)
                } else if (this.contact.updatedAt && this.contact.createdAt && parseInt(this.moment(this.contact.updatedAt).format('X')*1000) != parseInt(this.moment(this.contact.createdAt).format('X')*1000)){
                    let created = {}
                    created.date = this.contact.createdAt
                    created.logNote = 'This contact was added'
                    let updated = {}
                    updated.date = this.contact.updatedAt
                    updated.logNote = 'This contact was updated'
                    events.push(created)
                    events.push(updated)
                }
                this.tasks.forEach(task => {
                    if (task.contact && (task.contact.id == this.contact.id || task.contact == this.contact.id)) {
                        let obj = {}
                        obj.date = task.createdAt
                        obj.logNote = 'A new task was added for this contact'
                        events.push(obj)
                    }
                })
                if (this.contact.notes && this.contact.notes.length) {
                    this.contact.notes.forEach(note => {
                        let obj = {}
                        obj.date = note.createdAt
                        obj.logNote = 'A new note was added for this contact'
                        events.push(obj)
                    })
                }
				if (this.contact.unsub && this.contact.unsubDate){
					let obj = {}
					obj.date = new Date(parseInt(this.contact.unsubDate))
					obj.logNote = 'This contact was unsubscribed'
					events.push(obj)
				}
            }
            return events
        },
        refresh() {
            return this.time
        },
        opportunities() {
            let opps = []
            let time = this.refresh
            if (this.$store.state.contacts.opportunities) {
                let opportunities = this.$store.state.contacts.opportunities
                opportunities.forEach(opp => {
                    if (opp.contact && this.contactDetails && this.contactDetails.contact && opp.contact.id == this.contactDetails.contact.id) {
                        opps.push(opp)
                    }
					if (opp.secondaryContacts && opp.secondaryContacts.length && this.contactDetails && this.contactDetails.contact) {
						let ids = opp.secondaryContacts.map(s => s.id)
						if (ids.includes(this.contactDetails.contact.id)) {
							opps.push(opp)
						}
					}
                })
            }
            return opps
        },
        createDates(){
            let dates = {}
            this.events.forEach( x => {
                let xDate = new Date(x.date)
                let month = xDate.getMonth() + 1
                let day = xDate.getDate()
                let year = xDate.getFullYear()
                if (!dates.hasOwnProperty(`${month}` + '/' + `${day}` + `/` + `${year}`)){
                    let obj = {
                        date:`${month}` + '/' + `${day}` + `/` + `${year}`,
                        sorted:this.sortDates(`${month}` + '/' + `${day}` + `/` + `${year}`)
                    }
                    dates[obj.date] = obj
                }
            })
            let sorted = Object.values(dates).sort( (a,b) => {
                let {date:date1} = a
                let {date:date2} = b

                date1 = new Date(date1).getTime()
                date2 = new Date(date2).getTime()

                return date1 > date2 ? -1 : 1
            })
            return sorted
        },
        currentUser(){
            return this.$store.state.user
        },
        tags(){
            return this.$store.state.contacts.tags
        },
        showDetails(){
            return this.$store.state.contacts.contactDetails.show
        },
        contactDetails(){
            let contact = this.$store.state.contacts.contactDetails
            if (contact && contact.contact && contact.contact.notes && typeof contact.contact.notes != 'string'){
                contact.contact.notes.sort((a,b) => {
                    return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                })
                return contact
            }
            else {
                return contact
            }
        },
        tasks(){
            let time = this.updateTime
            let tasks = JSON.parse(JSON.stringify(this.$store.state.contacts.tasks))
            if (tasks && tasks.length) tasks = tasks.filter( x => x.relatedType == 'contact' && x.contact && this.contact && x.contact.id == this.contact.id)
            this.taskNum = tasks.length

            if (tasks && tasks.length){
                tasks.sort((a,b) => {
                    return a.dueDate - b.dueDate
                })
            }
            return tasks
        },
        appointments(){
            let time = this.refresh
            let appointments = JSON.parse(JSON.stringify(this.$store.state.contacts.appointments))
            if (appointments && appointments.length) appointments = appointments.filter( x => {
				if (x.relatedType == 'contact' && x.contact && x.contact.id == this.contact.id) return true
				return false
			})
            this.appointmentNum = appointments.length

            if (appointments && appointments.length){
                appointments.sort((a,b) => {
                    return a.date - b.date
                })
            }
            return appointments
        },
        instance(){
          return this.$store.state.instance
        },
        appData(){
          return this.$store.state.appData
        },
		nylasAccount() {
            return this.$store.state.contacts.nylasAccount || {}
        }
    },
    methods: {
		getFrom(from) {
            if (from.includes("<") && from.includes(">")) {
                let index = from.indexOf("<");
                if (index != -1) {
                    return from.substring(0, index - 1);
                }
                return "";
            } else return from;
        },
        getTo(to) {
            if (to.includes(",")) {
                let toName = "";
                let array = to.split(",");
                array.forEach((name) => {
                    if (name.includes("<") && name.includes(">")) {
                        let index = name.indexOf("<");
                        if (index != -1) {
                            if (toName.length)
                                toName =
                                    toName +
                                    `, ${name.substring(0, index - 1)}`;
                            else toName = toName + name.substring(0, index - 1);
                        }
                    }
                });
                return toName;
            } else if (
                to.includes("<") &&
                to.includes(">") &&
                !to.includes(",")
            ) {
                let index = to.indexOf("<");
                if (index != -1) {
                    return to.substring(0, index - 1);
                }
                return "";
            } else return to;
		},
		getDate(date) {
            let stringDate = moment(date).format("MMM DD, YYYY - hh:mm");
            return stringDate;
		},
		formatDate,
        transactionDate(tr){
            return formatDate(tr)
        },
        transactionPurchasers(tr){
            if (!tr.purchasers ) return 'No Purchaser(s)'
            if (!tr.purchasers.length) return 'No Purchaser(s)'
            return tr.purchasers.length < 3 ? tr.purchasers.filter( (x,xI) => xI < 2).map(x => `${x?.firstName} ${x?.lastName?.[0]}.`).join(', ') : `${tr.purchasers.length} Purchasers`
        },
        transactionName(tr){
            if (this.instance.productType == 'highrise'){
                let {product = {}, purchasers = [] } = tr
                let {floor = {}} = product
                let name = `${floor.name}`

                if (purchasers.length === 1) name += ` - (Buyer: ${purchasers[0].fullName})`
                else name += ` - ${purchasers.length} Purchasers`

                return name
            } else {
                let {product = {}, purchasers = [] } = tr
                let {lot = {}} = product
                let name = `Lot ${lot.name}`
                if (lot.address){
                    name += ` - ${lot.address}`
                } else {
                    if (purchasers.length === 1) name += ` - (Buyer: ${purchasers[0].fullName})`
                    else name += ` - ${purchasers.length} Purchasers`

                }
                return name
            }
        },
        getInactive(updatedAt) {
            let today = moment(new Date())
            let update = moment(updatedAt)
            return Math.round(today.diff(update, 'days', true))
        },
        deleteContact(contact) {
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete Lead')
            }
            let self = this
            this.$confirm({
                title: 'Delete Lead',
                content: h => <div>Do you want to delete <b>{contact.email}</b>? All information related to them will be lost.</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk(){
                    self.tasks.forEach( task => {
                        if (task.relatedType == 'contact' && (task.contact && task.contact.id) == contact.id){
                            self.$store.commit('DELETE_TASK', task)
                        }
                    })
                    self.appointments.forEach( appointment => {
                        if (appointment.relatedType == 'contact' && appointment.contact.id == contact.id){
                            self.$store.commit('DELETE_APPOINTMENT', appointment)
                        }
                    })
                    self.opportunities.forEach( opp => {
                        if (opp.contact && opp.contact.id == contact.id){
                            self.$store.commit('DELETE_OPPORTUNITY', opp)
                        }
                    })
                    self.$api.delete(`/contacts/:instance/${contact.id}`).then(({data}) => {
                        self.$store.commit('SET_TOTAL_RESULT', self.totalContacts - 1)
                        if (self.contacts.length == 1 && self.currentPage != 1){
                            self.currentPage = self.currentPage - 1
                            self.$store.dispatch('DO_SEARCH')
                        } else {
                            self.$store.dispatch('DO_SEARCH')
                        }
                        self.onClose({type:'delete',data})
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel(){
                    console.log('CANCELED')
                }
            })
        },
        deleteItem(type, id) {
            if (type == 'opportunity') {
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete opportunity')
                }
                let index = this.opportunities.findIndex(x => x.id == id)
                let name = ''
                if (index != -1) name = this.opportunities[index].name
                let self = this
                this.$confirm({
                    title: "Delete Opportunity",
                    content: h => <div>Do you want to delete <b>{name}</b>? All information related to them will be lost.</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.loading = true
                        self.$api.delete(`/opportunities/:instance/${id}`).then( ({data}) => {
                            self.loading = false
                            self.$store.commit('UPDATE_OPPORTUNITIES', data)
                        }).catch(err => {
							self.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            } else if (type == 'note') {
                let self = this
                this.$confirm({
                    title: "Delete Note",
                    content: h => <div>Do you want to delete this Note?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.loading = true
                        self.$api.delete(`/comments/:instance/${id}`).then( ({data}) => {
                            self.loading = false
                            self.$store.commit('DELETE_NOTE', data)
                        }).catch(err => {
							self.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            } else if (type == 'appointment') {
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete Appointments')
                }
                let self = this
                this.$confirm({
                    title: "Delete Appointment",
                    content: h => <div>Do you want to delete this appointment?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.loading = true
                        self.$api.delete(`/appointments/:instance/${id}`).then( ({data}) => {
                            self.$store.commit('DELETE_APPOINTMENT', data)
                            self.loading = false
                        }).catch(err => {
							self.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            }
        },
        getDateString(string) {
            if (string != '' && string != undefined) {
                let year = string.substring(0,4)
                let month = string.substring(5,7)
                let day = string.substring(8,10)
				if (this.dateFormat == 'YYYY/MM/DD') return `${year}/${month}/${day}`
				else if (this.dateFormat == 'DD/MM/YYYY') return `${day}/${month}/${year}`
				else {
					return `${month}/${day}/${year}`
				}
            } else {return ''}
        },
        checkComplete(e, id) {
            let find = this.tasks.find(x => x.id == id)
            if (find) {
                let obj = JSON.parse(JSON.stringify(find))
                obj.completed = e.target.checked
                this.$api.put(`/tasks/:instance/${id}`, obj).then( ({data}) => {
                    this.$store.commit('UPDATE_TASK', data)
                    this.updateTime = Date.now()
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
        },
        edit(type, id) {
            if (type == 'opportunity') {
                this.$store.commit('CLOSE_CONTACT_DETAILS')
                this.$router.push(`/opportunities/sales/${id}`)
            } else this.$store.commit('OPEN_EDIT_DRAWER', {type:type, id:id})
        },
        editContact(id) {
            this.$router.push(`/leads/${id}`)
            this.$store.commit('CLOSE_CONTACT_DETAILS')
        },
        convertLead() {
            if (this.contact && this.contact.id && this.contact.id != '') {
                this.$store.commit('ADD_NEW', 'Convert Opportunity')
                this.$store.commit('ADD_LEAD_ACTION', this.contact.id)
            }
        },
        getStage(stageId) {
            if (this.$store.state.contacts.allSettings && this.$store.state.contacts.allSettings.app && this.$store.state.contacts.allSettings.app.options && this.$store.state.contacts.allSettings.app.options.stages && this.$store.state.contacts.allSettings.app.options.stages.list) {
                let stages = this.$store.state.contacts.allSettings.app.options.stages.list
                let find = stages.find(x => x.id == stageId)
                if (find) {
                    return find.name
                } else return ''
            }
        },
        addNew(type){
            if (this.contact.id && this.contact.id != '') {
                this.$store.commit('ADD_NEW', type)
                this.$store.commit('ADD_LEAD_ACTION', this.contact.id)
            }
        },
        newNote(){
            this.$store.commit('ADD_NEW', 'Note')
            setTimeout(() => {
                this.$store.commit('UPDATE_QUERY_CONTACT', this.contact)
            },50)
        },
        newAppointment(){
            this.$store.commit('ADD_NEW', 'Appointment')
            setTimeout(() => {
                this.$store.commit('UPDATE_QUERY_CONTACT', this.contact)
            },50)
        },
        sendEmail(obj){
            if (obj.id && obj.id != '') {
				if (this.nylasAccount && this.nylasAccount.email_address){
					this.$store.commit('ADD_LEAD_ACTION', obj.id)
					this.$store.commit('ADD_NEW', 'Email')
				} else {
					this.$notification['error']({
						message: 'No Email Connection',
						description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
						duration: 5
					});
				}
            }
        },
        moment,
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        textTime(date){
            let x = new Date(date.date)
			x.setTime(x.getTime() + this.offsetDiffMs('get'));
            let hours = x.getHours()
            let minutes = x.getMinutes()
			minutes = minutes < 10 ? '0'+minutes : minutes

			let strTime = hours + ':' + minutes

			if (this.timeFormat == 12){
				let ampm = hours >= 12 ? 'PM' : 'AM'
				hours = hours % 12
				hours = hours ? hours : 12 // the hour '0' should be '12'
				strTime = hours + ':' + minutes + ' ' + ampm
			}

            return strTime
        },
		offsetDiffMs(type){
			let desiredTimezone = moment.tz(this.timezone);
			let desiredOffset = desiredTimezone.utcOffset();
			let currentOffset = new Date().getTimezoneOffset() * -1;
			let offsetDiff = 0
			let offsetDiffMs = 0
			if (currentOffset !== desiredOffset){
				if (type == 'set'){
					offsetDiff = currentOffset - desiredOffset;
				} else {
					offsetDiff = desiredOffset - currentOffset;
				}
				offsetDiffMs = offsetDiff * 60 * 1000;
			}
			return offsetDiffMs
		},
        textDate(date){
			const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let newDate = new Date(date)
			let month = monthNames[Number(newDate.getMonth())]
			return month + ' ' + newDate.getDate() + ', ' + newDate.getFullYear()
        },
        sortDates(date){
            let dates = []
            this.events.forEach( x => {
                let xDate = new Date(x.date)
                let month = xDate.getMonth() + 1
                let day = xDate.getDate()
                let year = xDate.getFullYear()
                if (date == (`${month}` + '/' + `${day}` + `/` + `${year}`)){
                    dates.push(x)
                }
            })
            dates.sort((a, b) => {
                return new Date(b.date) - new Date(a.date)
            })
            return dates
        },
        expandDetails(obj){
            this.$router.push(`/leads/${obj.id}`)
            this.onClose()
        },
        displayPhone(num){
            if (num != 'n/a' && num != '' && num != undefined) {
                let phone = num.toString().trim("").replace(/[^a-zA-Z0-9 ]/g, '')
                let first = phone.substring(0,3)
                let middle = phone.substring(3,6)
                let last = phone.substring(6,10)

                return '('+first+') '+middle+' '+last
            } else {
                return ''
            }
        },
        onClose(){
            this.$store.commit('CLOSE_CONTACT_DETAILS')
            this.showNotes = true,
            this.showTasks = true,
            this.showAppointments = true,
            this.showSubmissions = true,
            this.showTimeline = true,
            this.contact = {},
            this.taskNum = 0
            this.appointmentNum = 0
        },

		getInitial(user) {
			return (user?.firstName?.[0]?.toUpperCase() || '') + (user?.lastName?.[0]?.toUpperCase() || '')
		}
    },
  };
</script>
<style>
.collapse-icon .ant-collapse-arrow{
    left:0px !important;
}
</style>
<style lang="scss">
	.hide-scroll .ant-drawer-wrapper-body::-webkit-scrollbar {
		display: none;
	}
    .ant-timeline-item-last .ant-timeline-item .ant-timeline-item-tail {
        display: none;
    }
    .drawerScroll {
        .ps__thumb-x {
            display: none;
        }
        .ps__rail-y {
            display: none;
        }
    }
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .contact-tabs .ant-tabs-nav-container {
        text-align: center;
    }
    .contact-tabs .ant-tabs-bar {
        margin-left: -24px;
        margin-right: -24px;
    }
    .contact-tabs .ant-tabs-nav {
        font-size: 18px;
        .ant-tabs-tab {
            padding-left: 18px;
            padding-right: 18px;
        }
    }
    .owner-icon {
        border-radius: 30px;
        width: 30px;
        height: 30px;
        background-color: #F4F3F6;
    }
    .taskParagraph p{
        margin-bottom: 0 !important;
    }
</style>
<style lang="scss" scoped>
	.list-transactions:not(:first-child){
		margin-top: 2em;
	}
    .delete-button:hover{
        color:var(--danger) !important;
    }
    .action-button:hover{
        color:var(--orange) !important;
    }
    .contact-tabs {
        margin-left: -24px;
        margin-right: -24px;
        .tab-content {
            padding: 24px;
        }
    }
    .edit-delete {
        padding: 9px 0;
		cursor: pointer;
		color: #9EA0A5;
		transition:color .1s ease-in;
	}
	.edit-delete:hover {
		color: orange;
		transition:color .1s ease-in;
	}
    .more-option-icon{
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
        color: var(--orange) !important;
        cursor: pointer;
        background-color: var(--light-gray);
    }
    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }

    .smallIcon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        padding: 7px 0;
    }
</style>
<style>
.big-checkbox-circle .ant-checkbox-inner{
    width:30px;
    height:30px;
}
.big-checkbox-circle .ant-checkbox-inner::after{
    left:33%;
}
</style>
