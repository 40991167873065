<template>
	<div>
		<BHLoading :show="loading" />
		<a-card :bodyStyle="{ padding: '0' }">
			<div class="ml-2 mail-tab">
				<a-tabs v-model="selectedTab" size="large" @change="selectedThread = ''; selectedStoredMail === null">
					<a-tab-pane key="Integrated Account">
						<span slot="tab">
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title">
									Only emails from the integrated email account will be displayed for both outgoing and incoming messages related to the {{ $route.path.includes('opportunities') ? 'opportunity' : 'lead' }} record.

								</template>
								Integrated Account
								<a-icon type="question-circle" style="font-size: 12px" class="ml-1" />
							</a-tooltip>
						</span>
					</a-tab-pane>
					<a-tab-pane key="Sent History" v-if="$p >= 20">
						<span slot="tab">
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title">
									View all email threads sent from Bildhive's CRM to the {{ $route.path.includes('opportunities') ? 'opportunity' : 'lead' }} record from any user.
								</template>
								Sent History
								<a-icon type="question-circle" style="font-size: 12px" class="ml-1" />
							</a-tooltip>
						</span>
					</a-tab-pane>
					<div slot="tabBarExtraContent" class="mr-3 mt-1">
						<div v-if="selectedThread == ''">
							<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
								<div slot="title">Write New Email</div>
								<a-icon @click="addEmail"
									style="font-size: 25px; color: var(--orange); cursor: pointer;"
									type="plus-circle" />
							</a-tooltip>
						</div>
						<div v-else>
							<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
								<div slot="title">Go back to Email</div>
								<a-icon @click="showThread('')" style="font-size: 25px; cursor: pointer"
									type="arrow-left" />
							</a-tooltip>
						</div>
					</div>
				</a-tabs>
			</div>
			<template v-if="selectedTab === 'Integrated Account'">
				<template v-if="threads.length">
					<template v-if="selectedThread == ''">
						<div class="email-card" style="cursor: pointer" @click="showThread(thread.id)"
							v-for="(thread, threadI) in threads" :value="thread.id" :key="thread.id"
							@mouseover="hoveringMail = thread.id" @mouseleave="hoveringMail = ''">
							<div style="padding: 24px"
								v-if="thread.messages && thread.messages.length && thread.messages[0].subject">
								<div class="dF jSB">
									<div class="dF aC">
										<div class="smallIcon mr-3" style="background-color: #ffdead">
											<svg width="12" height="12" viewBox="0 0 20 20" fill="#F79425">
												<path id="Icon_material-comment" data-name="Icon material-comment"
													d="M22.99,5A2,2,0,0,0,21,3H5A2.006,2.006,0,0,0,3,5V17a2.006,2.006,0,0,0,2,2H19l4,4ZM19,15H7V13H19Zm0-3H7V10H19Zm0-3H7V7H19Z"
													transform="translate(-3 -3)" />
											</svg>
										</div>
										<div style="max-width: 600px">
											<span>
												<span style="color: #000">{{
													`${thread.messages[0].subject}`
												}}</span>
												<br />
												<span style="color: #9ea0a5">{{
													`from: ${getFrom(
														thread.messages[0].from
													)}`
												}}</span>
											</span>
											<div class="dF aC">
												<div style="color: #9ea0a5">
													{{
														`to: ${getTo(
															thread.messages[0].to
														)}`
													}}
												</div>
												<a-popover class="ml-2" placement="bottom">
													<template slot="content">
														<div class="dF aC">
															<div style="color: #9ea0a5;">
																From:
															</div>
															&nbsp;
															<div style="color: #000">
																{{
																	thread
																		.messages[0]
																		.from
																}}
															</div>
														</div>
														<div class="dF aC">
															<div style="color: #9ea0a5;">
																To:
															</div>
															&nbsp;
															<div style="color: #000">
																{{
																	thread
																		.messages[0]
																		.to
																}}
															</div>
														</div>
													</template>
													<a-icon style="cursor: pointer" type="caret-down" />
												</a-popover>
											</div>
										</div>
									</div>
									<div class="mt-4" style="color: #9ea0a5">
										{{ getDate(thread.messages[0].date) }}
									</div>
									<div v-if="hoveringMail == thread.id" class="absolute"
										style="right: 10px; z-index: 1000">
										<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
											<template slot="title">Delete</template>
											<div @click="deleteThread(thread.id)">
												<em style="color: #fd647c; cursor: pointer;"
													class="fe fe-trash-2 mr-3" />
											</div>
										</a-tooltip>
									</div>
								</div>

								<div v-if="
									thread.messages[0] &&
									thread.messages[0].snippet
								" class="mt-3">
									{{ thread.messages[0].snippet }}
								</div>
							</div>
							<hr v-if="threadI != threads.length - 1" style="margin: 0" />
						</div>
						<template v-if="!loading && !maxLoaded">
							<Observed @observed="loadMore" v-if="nextPage" :key="moreLoaded">
								<div class="py-3 dF fC aC jC" style="background:#eee;">
									<a-icon type="loading" /> Loading
								</div>
							</Observed>
						</template>
					</template>
					<template v-else>
						<div v-for="(message, messageI) in selectedMessages" :key="messageI"
							:value="message.id + messageI">
							<div style="padding: 24px">
								<div class="dF jSB">
									<div class="dF aC">
										<div class="smallIcon mr-3" style="background-color: #ffdead">
											<svg width="12" height="12" viewBox="0 0 20 20" fill="#F79425">
												<path id="Icon_material-comment" data-name="Icon material-comment"
													d="M22.99,5A2,2,0,0,0,21,3H5A2.006,2.006,0,0,0,3,5V17a2.006,2.006,0,0,0,2,2H19l4,4ZM19,15H7V13H19Zm0-3H7V10H19Zm0-3H7V7H19Z"
													transform="translate(-3 -3)" />
											</svg>
										</div>
										<div>
											<span>
												<span style="color: #000">{{
													`Subject: ${message.subject}`
												}}</span>&nbsp;
												<span style="color: #9ea0a5">{{
													`from ${message.from
														? getFrom(message.from)
														: `${user.firstName} ${user.lastName}`
													}`
												}}</span>
											</span>
											<div class="dF aC">
												<div style="color: #9ea0a5">
													{{ `to ${getTo(message.to)}` }}
												</div>
												<a-popover class="ml-2" placement="bottom">
													<template slot="content">
														<div class="dF aC">
															<div style="color: #9ea0a5;">
																From:
															</div>
															&nbsp;
															<div style="color: #000">
																{{ message.from }}
															</div>
														</div>
														<div class="dF aC">
															<div style="color: #9ea0a5;">
																To:
															</div>
															&nbsp;
															<div style="color: #000">
																{{ message.to }}
															</div>
														</div>
													</template>
													<a-icon style="cursor: pointer" type="caret-down" />
												</a-popover>
											</div>
										</div>
									</div>
									<div class="ml-2">
										<div style="color: #9ea0a5">
											{{ getDate(message.date) }}
										</div>
										<div clas="mt-2" style="
	                                            display: flex;
	                                            width: 100%;
	                                            justify-content: flex-end;
	                                        ">
											<div class="dF aC">
												<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
													<div slot="title">
														Reply to this Email
													</div>
													<svg @click="
														replyMessage(message)
														" style="
	                                                        color: #afb7c8;
	                                                        cursor: pointer;
	                                                    " aria-hidden="true" width="14" height="14" focusable="false"
														data-prefix="fas" data-icon="reply" class="
	                                                        action-icon
	                                                        svg-inline--fa
	                                                        fa-reply fa-w-16
	                                                    " role="img" xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512">
														<path fill="currentColor"
															d="M8.309 189.836L184.313 37.851C199.719 24.546 224 35.347 224 56.015v80.053c160.629 1.839 288 34.032 288 186.258 0 61.441-39.581 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 45.344-145.012-21.507-183.51-176.59-185.742V360c0 20.7-24.3 31.453-39.687 18.164l-176.004-152c-11.071-9.562-11.086-26.753 0-36.328z">
														</path>
													</svg>
												</a-tooltip>
												<a-tooltip class="ml-2" slot="extra"
													overlayClassName="change-tooltip-color">
													<div slot="title">
														Forward this Email
													</div>
													<svg @click="replyMessage(message, true)"
														style="color: #afb7c8; cursor: pointer;" aria-hidden="true"
														focusable="false" width="14" height="14" data-prefix="fas"
														data-icon="share"
														class=" action-icon svg-inline--fa fa-share fa-w-16 " role="img"
														xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
														<path fill="currentColor"
															d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z">
														</path>
													</svg>
												</a-tooltip>
												<a-tooltip class="ml-2" slot="extra"
													overlayClassName="change-tooltip-color">
													<div slot="title">
														Delete this Email
													</div>
													<svg @click="deleteMessage(message.id, 'messages')"
														style="color: #afb7c8; cursor: pointer;" aria-hidden="true"
														focusable="false" width="14" height="14" data-prefix="fas"
														data-icon="trash"
														class=" action-icon svg-inline--fa fa-trash fa-w-14 " role="img"
														xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
														<path fill="currentColor"
															d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z">
														</path>
													</svg>
												</a-tooltip>
											</div>
										</div>
									</div>
								</div>
								<div class="mt-3 min-h-full">
									<EmailFrame :key="message.date" :html="message.html"
										:attachments="message.attachments" />
								</div>
								<Mentions v-if="showReply && selectedMessage.id == message.id" :activeButton="true"
									:showMentions="false" :message="type === 'reply' ? '' : message.html"
									:template="false" :recipient="recipient" :type="type" @close="showReply = false"
									@previewEmail="(e) => sendEmail(message, e)" :okButton="'SEND'"
									:cancelButton="'CLOSE'" class="task__comment mt-5" />
							</div>
							<hr v-if="messageI != selectedMessages.length - 1" style="margin: 0" />
						</div>
					</template>
				</template>
				<template v-else>
					<div v-if="!(nylasAccount && nylasAccount.email_address)" style="padding: 24px;">
						<EmptyApp :text="'You haven\'t connected your email account. To connect, go to Settings and select Email & Calendar Settings > Integrations.'" :img="require('@/assets/emptyIcons/email.png')" :app="$store.state.theApp" />
					</div>
					<div v-else style="padding: 24px">
						This lead doesn't have any email...
					</div>
				</template>
			</template>
			<template v-else-if="selectedTab === 'Sent History'">
				<template v-if="storedMail.length">
					<template v-if="selectedThread == ''">
						<div class="email-card" style="cursor: pointer" @click="showStoredMail(email.id)"
							v-for="(email) in storedMail" :value="email.id" :key="email.id"
							@mouseover="hoveringMail = email.id" @mouseleave="hoveringMail = ''">
							<div style="padding: 24px">
								<div class="dF jSB">
									<div class="dF aC">
										<div class="smallIcon mr-3" style="background-color: #ffdead">
											<svg width="12" height="12" viewBox="0 0 20 20" fill="#F79425">
												<path id="Icon_material-comment" data-name="Icon material-comment"
													d="M22.99,5A2,2,0,0,0,21,3H5A2.006,2.006,0,0,0,3,5V17a2.006,2.006,0,0,0,2,2H19l4,4ZM19,15H7V13H19Zm0-3H7V10H19Zm0-3H7V7H19Z"
													transform="translate(-3 -3)" />
											</svg>
										</div>
										<div style="max-width: 600px">
											<span>
												<span style="color: #000">{{
													`${email.subject}`
												}}</span>
												<br />
												<span style="color: #9ea0a5">{{
													`from: ${email.from[0].name}`
												}}</span>
											</span>
											<div class="dF aC">
												<div style="color: #9ea0a5">
													{{
														`to: ${email.to[0].name}`
													}}
												</div>
												<a-popover class="ml-2" placement="bottom">
													<template slot="content">
														<div class="dF aC">
															<div style="color: #9ea0a5;">
																From:
															</div>
															&nbsp;
															<div style="color: #000">
																{{ email.from[0].email }}
															</div>
														</div>
														<div class="dF aC">
															<div style="color: #9ea0a5;">
																To:
															</div>
															&nbsp;
															<div style="color: #000">
																{{ email.to[0].email }}
															</div>
														</div>
													</template>
													<a-icon style="cursor: pointer" type="caret-down" />
												</a-popover>
											</div>
										</div>
									</div>
									<div class="mt-4" style="color: #9ea0a5">
										{{ getDate(email.date) }}
									</div>
								</div>
							</div>
						</div>
						<template v-if="!loading && !maxStoredMailLoaded">
							<Observed @observed="loadMoreStoredMail" v-if="storedMail.length < storedMailCount"
								:key="moreLoaded">
								<div class="py-3 dF fC aC jC" style="background:#eee;">
									<a-icon type="loading" /> Loading
								</div>
							</Observed>
						</template>
					</template>
					<template v-else-if="selectedStoredMail">
						<div style="padding: 24px">
							<div class="dF jSB">
								<div class="dF aC">
									<div class="smallIcon mr-3" style="background-color: #ffdead">
										<svg width="12" height="12" viewBox="0 0 20 20" fill="#F79425">
											<path id="Icon_material-comment" data-name="Icon material-comment"
												d="M22.99,5A2,2,0,0,0,21,3H5A2.006,2.006,0,0,0,3,5V17a2.006,2.006,0,0,0,2,2H19l4,4ZM19,15H7V13H19Zm0-3H7V10H19Zm0-3H7V7H19Z"
												transform="translate(-3 -3)" />
										</svg>
									</div>
									<div>
										<span>
											<span style="color: #000">{{
												`Subject: ${selectedStoredMail.subject}`
											}}</span>&nbsp;
											<span style="color: #9ea0a5">{{
												`from ${selectedStoredMail.from[0].name}`
											}}</span>
										</span>
										<div class="dF aC">
											<div style="color: #9ea0a5">
												{{ `to ${selectedStoredMail.to[0].name}` }}
											</div>
											<a-popover class="ml-2" placement="bottom">
												<template slot="content">
													<div class="dF aC">
														<div style="color: #9ea0a5;">
															From:
														</div>
														&nbsp;
														<div style="color: #000">
															{{ selectedStoredMail.from[0].email }}
														</div>
													</div>
													<div class="dF aC">
														<div style="color: #9ea0a5;">
															To:
														</div>
														&nbsp;
														<div style="color: #000">
															{{ selectedStoredMail.to[0].email }}
														</div>
													</div>
												</template>
												<a-icon style="cursor: pointer" type="caret-down" />
											</a-popover>
										</div>
									</div>
								</div>
								<div class="ml-2">
									<div style="color: #9ea0a5">
										{{ getDate(selectedStoredMail.date) }}
									</div>
								</div>
							</div>
							<div class="mt-3 min-h-full">
								<EmailFrame :key="selectedStoredMail.date" :html="selectedStoredMail.body"
									:attachments="selectedStoredMail.files || []" />
							</div>
						</div>
					</template>
				</template>
				<template v-else>
					<div style="padding: 24px">
						This lead doesn't have any email...
					</div>
				</template>
			</template>
		</a-card>
	</div>
</template>

<script>
	import EmailFrame from "@/components/common/EmailFrame";
	import EmptyApp from 'bh-mod/components/common/EmptyApp'
	import BHLoading from "bh-mod/components/common/Loading";
	import Mentions from "@/components/common/Mentions";
	import moment from "moment";
	import Observed from '@/components/common/Observed'

	export default {
		components: {
			BHLoading,
			Mentions,
			EmailFrame,
			Observed,
			EmptyApp
		},
		props: {
			id: {
				type: String,
				default: "",
			},
			email: {
				type: String,
				default: "",
			},
		},
		computed: {
			user() {
				return this.$store.state.user.user;
			},
			allContacts() {
				return this.$store.state.contacts.allContacts;
			},
			externalContacts() {
				return this.$store.state.contacts.externalContacts;
			},
			recipient() {
				if (!this.selectedMessage) {
					return "";
				}
				return this.type === "reply"
					? this.selectedMessage.from
						.substring(
							this.selectedMessage.from.indexOf("<") + 1,
							this.selectedMessage.from.indexOf(">")
						)
						.trim()
					: "";
			},
			nextPage() {
				if (!this.threads.length) return 0
				return this.threads[this.threads.length - 1].nextPageToken || 0
			},
			nylasAccount() {
				return this.$store.state.contacts.nylasAccount || {}
			}
		},
		data() {
			return {
				selectedThread: "",
				selectedMessages: [],
				loading: false,
				threads: [],
				showReply: false,
				selectedMessage: "",
				hoveringMail: "",
				type: "reply",
				maxLoaded: false,
				moreLoaded: 2,
				storedMail: [],
				selectedTab: 'Integrated Account',
				maxStoredMailLoaded: false,
				selectedStoredMail: null,
				storedMailCount: 0,
				currentPage: 1,
				pageSize: 10
			};
		},
		methods: {
			replyMessage(message, forward = false) {
				this.showReply = true;
				this.selectedMessage = message;
				if (forward) {
					this.type = "forward";
				} else {
					this.type = "reply";
				}
			},
			deleteThread(id) {
				this.$api
					.delete(`/contacts/:instance/threads/${id}`)
					.then(({ data }) => {
						this.$message.success("Email Deleted successfully.");
						this.fetchThreads();
						this.selectedMessage = "";
						this.selectedThread = "";
					})
					.catch((err) => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err, `An error occurred while deleting mail thread. Please try again.`))
						}
					});
			},
			deleteMessage(id, type = "threads") {
				this.$api
					.delete(`/contacts/:instance/${type}/${id}`)
					.then(({ data }) => {
						this.$message.success("Email Deleted successfully.");
						this.fetchThreads();
						this.selectedMessage = "";
						this.selectedThread = "";
					})
					.catch((err) => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err, `An error occurred while sending mail message. Please try again.`))
						}
					});
			},
			sendEmail(message, { html, recipient = "" }) {
				let innerText = html.replace(/<p><br[\/]?><[\/]?p>/g, "");
				if (!innerText.trim())
					return this.$message.error("Email needs a body!");
				else {
					let email = {};
					email.html = html;
					email.subject = message.subject;
					email.to =
						recipient ||
						message.from
							.substring(
								message.from.indexOf("<") + 1,
								message.from.indexOf(">")
							)
							.trim();
					email.id = message.id;
					this.loading = true;
					this.$api
						.post(`/contacts/:instance/sendmail`, email)
						.then(({ data }) => {
							this.loading = false;
							this.showReply = false;
							this.selectedMessages.push(email);
							let index = this.threads.findIndex(
								(x) => x.id == this.selectedThread
							);
							if (index != -1) {
								this.threads[index].messages.push(email);
							}
							this.$notification["success"]({
								message: "Email Sent",
								description:
									"Your email has been sent successfully.",
								duration: 4,
							});
						})
						.catch((err) => {
							this.loading = false;
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, `An error occurred while sending mail message. Please try again.`))
							}
						});
				}
			},
			moment,
			getDate(date) {
				let stringDate = moment(date).format("MMM DD, YYYY - hh:mm");
				return stringDate;
			},
			showThread(threadId) {
				this.selectedThread = threadId;
				if (threadId != "") {
					let index = this.threads.findIndex((x) => x.id == threadId);
					if (index != -1) {
						this.selectedMessages = JSON.parse(
							JSON.stringify(this.threads[index].messages)
						);
					}
				} else this.selectedMessages = [];
				this.showReply = false;
				this.selectedMessage = "";
			},
			showStoredMail(mailId) {
				this.selectedThread = mailId;
				if (mailId != "") {
					let index = this.storedMail.findIndex((x) => x.id == mailId);
					if (index != -1) {
						this.selectedStoredMail = this.storedMail[index]
					}
				} else this.selectedStoredMail = null;
				this.showReply = false;
			},
			addEmail() {
				if (this.nylasAccount && this.nylasAccount.email_address){
					this.$store.commit("ADD_NEW", "Email");
					this.$store.commit("ADD_LEAD_ACTION", this.id);
				} else {
					this.$notification['error']({
						message: 'No Email Connection',
						description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
						duration: 5
					});
				}
			},
			getContact() {
				if (this.id != "") {
					if (this.allContacts[this.id]) {
						if (
							this.allContacts[this.id].firstName &&
							this.allContacts[this.id].lastName &&
							this.allContacts[this.id].firstName.trim() &&
							this.allContacts[this.id].lastName.trim()
						)
							return `${this.allContacts[this.id].firstName} ${this.allContacts[this.id].lastName
								}`;
						else return `${this.allContacts[this.id].email}`;
					} else if (this.externalContacts[this.id]) {
						if (
							this.externalContacts[this.id].firstName &&
							this.externalContacts[this.id].lastName &&
							this.externalContacts[this.id].firstName.trim() &&
							this.externalContacts[this.id].lastName.trim()
						)
							return `${this.externalContacts[this.id].firstName} ${this.externalContacts[this.id].lastName
								}`;
						else return `${this.externalContacts[this.id].email}`;
					} else {
						let name = "";
						this.loading = true;
						this.$api
							.get(`/contacts/:instance/${this.id}`)
							.then(({ data }) => {
								if (
									data.firstName &&
									data.lastName &&
									data.firstName.trim() &&
									data.lastName.trim()
								)
									name = `${data.firstName} ${data.lastName}`;
								else name = `${data.email}`;
								this.loading = false;
							}).catch(err => {
								this.loading = false;
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							});
						return name;
					}
				}
			},
			getFrom(from) {
				if (from.includes("<") && from.includes(">")) {
					let index = from.indexOf("<");
					if (index != -1) {
						return from.substring(0, index - 1);
					}
					return "";
				} else return from;
			},
			getTo(to) {
				if (to.includes(",")) {
					let toName = "";
					let array = to.split(",");
					array.forEach((name) => {
						if (name.includes("<") && name.includes(">")) {
							let index = name.indexOf("<");
							if (index != -1) {
								if (toName.length)
									toName =
										toName +
										`, ${name.substring(0, index - 1)}`;
								else toName = toName + name.substring(0, index - 1);
							}
						}
					});
					return toName;
				} else if (
					to.includes("<") &&
					to.includes(">") &&
					!to.includes(",")
				) {
					let index = to.indexOf("<");
					if (index != -1) {
						return to.substring(0, index - 1);
					}
					return "";
				} else return to;
			},
			fetchThreads() {
				this.loading = true;
				this.$api
					.get(`/contacts/:instance/inbox?contact=${this.email}`)
					.then(({ data }) => {
						this.threads = data;
						this.loading = false;
					}).catch(err => {
						this.loading = false;
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					});
			},

			loadMore() {
				this.$api
					.get(`/contacts/:instance/inbox/${this.moreLoaded}?contact=${this.email}`)
					.then(({ data }) => {
						if (data && data.length) {
							this.threads = [...this.threads, ...data];
							this.moreLoaded++;
						} else {
							this.maxLoaded = true
						}
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					});
			},

			async loadMoreStoredMail() {
				this.currentPage++;
				await this.getStoredMail();
				this.moreLoaded++;
			},

			async getStoredMailCount() {
				try {
					const { data } = await this.$api.get(`email-messages/:instance/count?contact=${this.email}`)
					this.storedMailCount = data
				} catch (err) {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while fetching sent email history count. Please try again.`))
					}
				}
			},

			async getStoredMail() {
				try {
					if (this.storedMail.length === this.storedMailCount && this.currentPage !== 1) {
						this.maxStoredMailLoaded = true;
						return;
					}
					let start = (this.currentPage - 1) * this.pageSize;
					const { data } = await this.$api.get(`email-messages/:instance?contact=${this.email}&_start=${start}&_limit=${this.pageSize}&_sort=createdAt:DESC`)
					this.storedMail.push(...data);
					if (this.storedMail.length === this.storedMailCount) {
						this.maxStoredMailLoaded = true;
					}
				} catch (err) {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while fetching sent email history. Please try again.`))
					}
				}
			}
		},
		created() {
			if (this.email != undefined && this.email != "") {
				this.fetchThreads();
				this.getStoredMailCount();
				this.getStoredMail()
			}
		},
	};
</script>

<style scoped>
	.email-card:hover {
		background-color: var(--off-white-dark);
	}

	.action-icon:hover {
		color: var(--orange) !important;
	}
</style>
<style>
	.timeline-border .ant-timeline-item .ant-timeline-item-tail {
		border-left: #2dd479 2px solid;
	}

	.collapse-border.ant-collapse {
		border: none !important;
	}

	.collapse-border.ant-collapse>.ant-collapse-item {
		border-bottom: none !important;
	}

	.collapse-border .ant-collapse-content {
		border: none !important;
	}
</style>

<style>
	.mail-tab .ant-tabs-extra-content {
		line-height: normal;
	}
</style>
