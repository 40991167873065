<template>
    <a-modal
        :visible="editTaskVisible"
        @cancel="closeEditModal"
        centered
        :footer="null"
        :width="'1000px'"
        >
        <BHLoading :show="loading" />
        <div v-if="editTask.hasOwnProperty('id')" class="dF" style="padding:25px">
            <template v-if="editTask.createdBy">
                <a-avatar class="mr-3" v-if="editTask.createdBy && editTask.createdBy.avatar && editTask.createdBy.avatar !== ''" :size="70" :src="editTask.createdBy.avatar"></a-avatar>
                <a-avatar class="mr-3" v-else style="font-size: 30px; background-color:#1070CA" shape="circle" :size="70">
                    <template v-if="(editTask.createdBy && typeof editTask.createdBy === 'string' || editTask.createdBy instanceof String)">{{users[editTask.createdBy].firstName[0].toUpperCase() + users[editTask.createdBy].lastName[0].toUpperCase()}}</template>
                    <template v-else>{{editTask.createdBy && editTask.createdBy.firstName[0].toUpperCase() + editTask.createdBy.lastName[0].toUpperCase()}}</template>
                </a-avatar>
            </template>

            <div style="flex: 1;">
                <div class="w-full">
                    <h3 class="mb-3">{{editTask.title}}</h3>
                    <div class="dF" style="font-size: 13px;">
                        <div style="color: #818181;">Created by&nbsp;</div>
                        <div>
                            <template v-if="(editTask.createdBy && typeof editTask.createdBy === 'string' || editTask.createdBy instanceof String)"><strong>{{users[editTask.createdBy].firstName + ' ' + users[editTask.createdBy].lastName}}</strong></template>
                            <template v-else><strong>{{editTask.createdBy && editTask.createdBy.firstName + ' ' + editTask.createdBy.lastName}}</strong></template>
                        </div>
                        <div style="color: #818181;">&nbsp;on&nbsp;{{editTask.createdAt && editTask.createdAt.substring(0,10)}}</div>

                    </div>
                </div>



                <div v-if="editable" class="mt-5 mb-3">
                   <EditDescription :text="editTask.description" @update="updateText"/>
                </div>
                <div class="mt-5 mb-3" v-else v-html="editTask.description"/>




                <!-- Files from the store, added files -->
                <div v-if="editTask.files">
                    <div v-for="item in editTask.files" :key="item.name">

                        <!-- If image -->
                        <div v-if="item.mime.includes('image')" class="dF mb-3 mt-3" style="align-items:start">
                            <div class="relative dF" style="height: auto; width: auto;">
                                <img style="max-width:100%; max-height:250px" :src="item.url" />
                                <div class="absolute dF w-full" style="justify-content: flex-end; height: auto;">
                                    <div class="dF" style="background-color:var(--off-white-dark);">
                                        <div style="padding: 15px;">
                                            <i @click="downloadFile(item)" class="fe fe-download" style="font-size: 20px;" />
                                        </div>
                                        <div style="padding: 15px;">
                                            <i @click="deleteFile(item.id)" class="fe fe-trash-2" style="font-size: 20px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- If pdf/doc -->
                        <div v-else-if="item.mime.includes('pdf') || item.mime.includes('doc')" style="padding:15px; background-color:var(--off-white-dark); align-items: center" class="mb-3 mt-3 w-full dF">
                            <div class="dF jSB w-full" style="align-items: center;">
                                <div>
                                    <a-icon v-if="item.mime.includes('pdf')" class="mr-3" style="color:var(--danger)" type="file-pdf" />
                                    <a-icon v-if="item.mime.includes('doc')" class="mr-3" style="color:var(--blue)" type="file-word" />
                                    {{item.name}}
                                </div>
                                <div class="dF" style="justify-content:flex-end">
                                    <i @click="downloadFile(item)" style="font-size:20px;" class="fe fe-download mr-3" />
                                    <i @click="deleteFile(item.id)" style="font-size:20px;" class="fe fe-trash-2" />
                                </div>
                            </div>
                        </div>
                        <div v-else style="padding:15px; background-color:var(--off-white-dark); align-items: center" class="mb-3 mt-3 w-full dF">
                            <div class="dF jSB w-full" style="align-items: center;">
                                <div>
                                    <a-icon v-if="item.mime.includes('excel')" class="mr-3" style="color:var(--teal)" type="file-excel" />
                                    <a-icon v-else class="mr-3" type="file" />
                                    {{item.name}}
                                </div>
                                <div class="dF" style="justify-content:flex-end">
                                    <i @click="downloadFile(item)" style="font-size:20px;" class="fe fe-download mr-3" />
                                    <i @click="deleteFile(item.id)" style="font-size:20px;" class="fe fe-trash-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Files just added now -->
                <!-- <div v-if="files.length">
                    <div v-for="item in files" :key="item.name">
                        <div class="dF mb-3 mt-3" style="align-items:start" v-if="item.mime.includes('image')">
                            <div class="relative dF" style="height: auto; width: auto;">
                                <img style="max-width:100%; max-height:400px" :src="item.url" />
                                <div class="absolute dF w-full" style="justify-content: flex-end; height: auto;">
                                    <div class="dF" style="background-color:var(--off-white-dark);">
                                        <div style="padding: 15px;">
                                            <i class="fe fe-download" style="font-size: 20px;" />
                                        </div>
                                        <div style="padding: 15px;">
                                            <i class="fe fe-trash-2" style="font-size: 20px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="padding:15px; background-color:var(--off-white-dark); align-items: center" class="mb-3 mt-3 w-full dF" v-else-if="item.mime.includes('pdf')">
                            <div class="dF jSB w-full" style="align-items: center;">
                                <div>
                                    <a-icon class="mr-3" style="color:var(--danger)" type="file-pdf" />{{item.name}}
                                </div>
                                <div class="dF" style="justify-content:flex-end">
                                    <i style="font-size:20px;" class="fe fe-download mr-3" />
                                    <i style="font-size:20px;" class="fe fe-trash-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div @click="uploadFiles" style="color:#3F3356; cursor:pointer; background-color:var(--off-white-dark); padding:15px; align-items:center; display:flex;"><i style="font-size:20px" class="fe fe-file-plus mr-3" />ATTACH FILES</div>

            </div>

            <div class="ml-5" style="width:200px">

                <!-- Contact -->
                <div v-if="editTask.contact" class="mb-2" style="color:#9EA0A5">Contact</div>

                <div class="dF mb-4" style="align-items:center">
                    <template v-if="editTask.relatedType === 'contact'">
                        <a-avatar class="mr-2" v-if="editTask.contact && editTask.contact.avatar" :src="editTask.contact.avatar" />
                        <a-avatar class="mr-2" v-else style="color:white; background-color: #1070CA">{{editTask.contact.firstName && editTask.contact.lastName && editTask.contact.firstName.trim() && editTask.contact.lastName.trim() ? editTask.contact.firstName[0].toUpperCase() + editTask.contact.lastName[0].toUpperCase() : editTask.contact.email[0].toUpperCase()}}</a-avatar>
                        {{editTask.contact.firstName && editTask.contact.lastName && editTask.contact.firstName.trim() && editTask.contact.lastName.trim() ? editTask.contact.firstName + ' ' + editTask.contact.lastName : editTask.contact.email}}
                    </template>
                    <template v-if="editTask.relatedType === 'opportunity'">
                        <a-avatar class="mr-2" style="color:white; background-color: #1070CA">{{ editTask.opportunity && editTask.opportunity.name ? editTask.opportunity.name[0].toUpperCase():''}}</a-avatar>
                        {{editTask.opportunity && editTask.opportunity.name ? editTask.opportunity.name:''}}
                    </template>
                </div>

                <!-- Priority Level -->
                <div v-if="editTask.priority" class="mb-2" style="color:#9EA0A5">Priority Level</div>

                <a-popover v-if="editTask.priority == 'high'" placement="bottom" trigger="click">
                    <template slot="content" >
                        <p style="cursor:pointer">HIGH PRIORITY</p>
                        <p @click="selectPriority('medium')" style="cursor:pointer">MED PRIORITY</p>
                        <p @click="selectPriority('low')" style="cursor:pointer">LOW PRIORITY</p>
                    </template>
                    <div class="mb-4" style="cursor:pointer; background-color:var(--danger);color:white; border-radius:4px; text-align:center">HIGH PRIORITY</div>
                </a-popover>

                <a-popover v-if="editTask.priority == 'medium'" placement="bottom" trigger="click">
                    <template slot="content" >
                        <p @click="selectPriority('high')" style="cursor:pointer">HIGH PRIORITY</p>
                        <p style="cursor:pointer">MED PRIORITY</p>
                        <p @click="selectPriority('low')" style="cursor:pointer">LOW PRIORITY</p>
                    </template>
                    <div class="mb-4" style="cursor:pointer; background-color:var(--orange);color:white; border-radius:4px; text-align:center">MED PRIORITY</div>
                </a-popover>

                <a-popover v-if="editTask.priority == 'low'" placement="bottom" trigger="click">
                    <template slot="content" >
                        <p @click="selectPriority('high')" style="cursor:pointer">HIGH PRIORITY</p>
                        <p @click="selectPriority('medium')" style="cursor:pointer">MED PRIORITY</p>
                        <p style="cursor:pointer">LOW PRIORITY</p>
                    </template>
                    <div class="mb-4" style="cursor:pointer; background-color:var(--teal);color:white; border-radius:4px; text-align:center">LOW PRIORITY</div>
                </a-popover>

                <!-- Task Type -->
                <div v-if="editTask.type" class="mb-2" style="color:#9EA0A5">Task Type</div>
                <div class="mb-4">{{editTask.type && editTask.type != '' ? getTaskType(editTask.type) : ''}}</div>

                <!-- Assignee(s) -->
                <div class="mb-2" style="color:#9EA0A5">Assignee(s)</div>

                <div class="dF mb-4">
                    <div v-for="member in editTask.assignTo" :key="member.id">
                        <a-tooltip class="mr-1" overlayClassName="change-tooltip-color">
                            <template slot="title">
                                {{member.firstName + ' ' + member.lastName}}
                            </template>
                            <a-avatar v-if="member.avatar && member.avatar.trim()" :src="member.avatar" />
                            <a-avatar style="background-color:#1070CA" v-else shape="circle">{{member.firstName[0].toUpperCase() + member.lastName[0].toUpperCase()}}</a-avatar>
                        </a-tooltip>
                    </div>
                    <a-popover trigger="click" placement="bottom">
                        <div slot="content" class="dF">
                            <a-select
                                v-model="assignees"
                                style="width:200px"
                                mode="multiple"
                                placeholder="Type to search..."
                                size="large"
                                :filter-option="filterOption"
                                :not-found-content="null"
                                @search="searchAssign">
                                <a-select-option v-for="user in userSource" :key="user.id" :value="user.id">
                                    {{ user.name }}
                                </a-select-option>
                            </a-select>
                            <div class="ml-3">
                                <a-button size="large" @click="saveAssignee" type="primary">SAVE</a-button>
                            </div>

                        </div>
                        <a-icon style="cursor:pointer; padding:8px; border-radius:50%; border:1px dashed var(--med-gray)" type="plus" />
                    </a-popover>

                </div>

                <!-- Due Date -->
                <div class="mb-2" style="color:#9EA0A5">Due Date</div>
                <div>
                    <a-date-picker @change="onChange" v-model="editTask.dueDate"></a-date-picker>
                </div>
            </div>
        </div>


        <!-- Comments -->
        <div style="width: calc(100% - 200px - 3rem); padding: 0 25px;">
            <h5>Comments ({{editTask.comments ? editTask.comments.length:''}})</h5>
            <hr>
        </div>
        <div @mouseover="showDelete(comment, commentI)" @mouseleave="deleteButton = -1" style="calc(100% - 200px - 3rem); padding:25px" class="dF" v-for="(comment, commentI) in comments" :key="commentI">
            <div>
                <a-avatar v-if="comment.user && comment.user.avatar && comment.user.avatar !== ''" :size="50" :src="comment.user.avatar" />
                <a-avatar style="background-color:#1070CA" :size="50" v-else>{{comment.user.firstName && comment.user.firstName.substring(0,1)}}</a-avatar>
            </div>

            <div class="ml-3" style="flex: 1;">
                <div class="dF w-full">
                    <strong>{{comment.user==null? '':comment.user.firstName+ ' ' + comment.user.lastName}}</strong>
                    <p class="ml-5" style="color:#818181">{{comment.createdAt && comment.createdAt.substring(0,10)}}</p>
                    <div style="flex: 1; display: flex; justify-content:flex-end;">
                        <i @click="deleteComment(comment)" style="cursor:pointer; font-size:20px;" v-if="deleteButton == commentI" class="fe fe-trash-2 delete-button" />
                    </div>
                </div>

                <div v-if="user.user.id == comment.user.id" >
                    <MenuBubble @save="(e) => saveEdit(e,comment)" :text="comment.content"/>

                </div>
                <div v-else v-html="comment.content" />
                <div v-if="comment.editted" style="color:#818181">Edited {{comment.updatedAt && comment.updatedAt.substring(0,10)}}</div>

                <!-- <strong v-for="(tag,tagI) in comment.tags" :key="tag+tagI">@{{tag}}</strong> -->
                <!-- <div class="dF">
                    <p style="color:#818181" >Edited {{comment.updatedAt.substring(0,10)}}</p>

                    <a-popover trigger="click" overlayClassName="popoverStyle">
                        <div slot="content">

                            <div @click="editComment(comment)" class="popoverContent"><i class="fe fe-edit-2 mr-3" />Edit Comment</div>
                            <div @click="deleteComment(comment)" class="popoverContent"><i class="fe fe-trash-2 mr-3" />Delete Comment</div>


                        </div>
                        <i class="fe fe-more-horizontal ml-5" style="font-size:20px; cursor: pointer;" />
                    </a-popover>

                </div> -->
            </div>
        </div>
        <div style="width:calc(100% - 200px - 3rem); padding:25px; align-items:center;" class="dF">
            <a-avatar v-if="user.user && user.user.avatar && user.user.avatar !== ''" class="mr-3" :size="50" :src="user.user.avatar" />
            <a-avatar v-else class="mr-3" style="background-color:#1070CA" :size="50">{{user.user.firstName && user.user.firstName.substring(0,1)}}</a-avatar>
            <div class="relative" style="flex: 1;">
                <!-- <Mentions :type="'taskComment'" :message="newComment.content" :template="false" :okButton="'SAVE'" :cancelButton="'CANCEL'" class="email-mentions" />  -->
                <!-- <a-textarea v-model="newComment.content" :style="newComment.content == ''? {'border-bottom': '1px solid #d9d9d9'} : {'border-bottom': 'none'}" class="replyBox" placeholder="Add a comment..." :rows="3" /> -->
                <TaskComment v-if="!loading" :message="message" @save="saveComment" class="task__comment" />
                <!-- <div v-if="newComment.content != ''" class="dF aB w-full" style="background-color: white; padding: 10px; border: 1px solid #d9d9d9; border-top: none; border-bottom: 1px solid #d9d9d9; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px;">
                    <div style="flex: 1 0 0;">

                    </div>
                    <div class="dF" style="flex: 0 1 0;">
                        <a-button @click="cancelComment" class="mr-3">CANCEL</a-button>
                        <a-button @click="saveComment" type="primary">SAVE</a-button>
                    </div>
                </div> -->
            </div>
            <!-- <a-input size="large" placeholder="Add a comment..."></a-input> -->
        </div>
    </a-modal>
</template>

<script>
import Mentions from '@/components/common/Mentions'
import EditText from '@/components/common/EditText'
import MenuBubble from '@/components/contacts/MenuBubble'
import MentionList from '@/components/contacts/MentionList'
import EditDescription from '@/components/contacts/EditDescription'
import TaskComment from '@/components/contacts/TaskComment'
import moment from 'moment'
import BHLoading from 'bh-mod/components/common/Loading'
// import MentionList from '@/components/contacts/MentionList'
const FileSaver = require('file-saver')

export default {
	name: 'EditTaskModal',
    components:{
        EditText, MenuBubble, EditDescription,Mentions,MentionList,TaskComment,BHLoading
    },
    props:{

        MentionList
    },
    watch: {
        editTask:{
            handler(val) {
                console.log('changing obj', val)

            },
            deep:true
        }
    },
    data(){
        return{
            loading:false,
            message:'',
            deleteButton:-1,
            dueDate:'',
            userSource:[],
            newComment:{
                content:'',
            },
            files:[],
            assignees:[],
        }
    },
    computed:{
        taskTypes() {
            if (this.$store.state.contacts.allSettings && this.$store.state.contacts.allSettings.app && this.$store.state.contacts.allSettings.app.options && this.$store.state.contacts.allSettings.app.options.taskType) {
                return this.$store.state.contacts.allSettings.app.options.taskType
            } else {
                return [
                    {name:'To-do', id:'todo'},
                    {name:'Call', id:'call'},
                    {name:'Follow up', id:'followup'}
                ]
            }
        },
        editable(){
            let index = this.editTask.assignTo.findIndex(x => x.id == this.user.user.id)
            if (index != -1) return true
            else return false
        },
        editTaskVisible(){
            return this.$store.state.contacts.editTask.visible
        },
        editTask(){
            let task = JSON.parse(JSON.stringify(this.$store.state.contacts.editTask.task))
            if (task.dueDate == 0 || task.dueDate == null){
                task.dueDate = ''
            } else {
                task.dueDate = this.moment(task.dueDate).format()
            }
            return task
        },
        comments(){
            let comments = this.editTask.comments || []
            comments.forEach(com => {
                if (typeof com.user == 'string') {
                    let user = this.storeUsers.find(x => x.id == com.user)
                    com.user = user
                }
                let created = parseInt(this.moment(com.createdAt).format('X'))
                let updated = parseInt(this.moment(com.updatedAt).format('X'))
                if (created < updated) {
                    com.editted = true
                }
            })
            return comments
        },
        instance(){
            return this.$store.state.instance
        },
        user(){
            return this.$store.state.user
        },
        users(){
            console.log('USERSSSSSS', this.$store.state.contacts.users)
            let users = this.$store.state.contacts.users
            let obj = {}
            users.forEach( x => {
                console.log('USER', x)
                obj[x.id] = x
            })
            console.log('OBJJJJ USERS', obj)
            return obj

        },
        storeUsers(){
            return this.$store.state.contacts.users.filter(x => x!=null)
        }
    },
    methods:{
		uploadFiles(){
			this.$store.commit('SET_MULTIPLE_IMAGES', true)
			this.$store.commit('MEDIA_SELECT',{callback:this.selectFiles,type:'all'})
		},
        getTaskType(taskId) {
            let find = this.taskTypes.find(x => x.id == taskId)
            if (find) return find.name
            else return ''
        },
        showDelete(comment, index) {
            if (comment.user.id == this.user.user.id) {
                this.deleteButton = index
            }
        },
        saveEdit(e, comment){
            if (e == '') {
                console.log('no edit to be updated!')
            }
            else if (e.length > 7) {
                let sendComment = JSON.parse(JSON.stringify(comment))
                sendComment.content = e
                console.log('sendComment', sendComment)
                this.$api.put(`/comments/:instance/${sendComment.id}`, sendComment).then( ({data}) => {
                    console.log('update comment', data)
                    this.$store.commit('UPDATE_COMMENT', data)
                    this.$emit('updatetable')
                    this.test = Date.now()
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            } else if (e.length == 7) {
                this.$api.delete(`/comments/:instance/${comment.id}`).then( ({data}) => {
                    console.log('delete comment', data)
                    this.$store.commit('DELETE_COMMENT', data)
                    this.$emit('updatetable')
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }

        },
        downloadFile(item) {
            FileSaver.saveAs(item.url, item.name);
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        deleteFile(id) {
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete File')
            }
            console.log('fileee',id)
            let self = this
            this.$confirm({
                title: "Delete File",
                content: h => <div>Do you want to delete this File?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    console.log('filessssss', self.editTask.files)
                    let files = self.editTask.files
                    let index = files.findIndex(file => file.id == id)
                    if (index != -1) {
                        files.splice(index,1)
                    }
                    self.$api.put(`/tasks/${self.instance.id}/${self.editTask.id}`, {files}). then(({data}) => {
	                    console.log('just updated', data)
	                    self.$store.commit('UPDATE_TASK', data)
	                    self.$emit('updatetable')
		            }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        moment,
        saveAssignee(){
            console.log('aaa', this.assignees)
            let list = []
            this.editTask.assignTo.forEach(x => {
                list.push(x.id)
            })
            this.assignees.forEach(a => {
                let index = list.findIndex(x => x == a)
                if (index == -1) {
                    list.push(a)
                }
            })
            // this.storeUsers.forEach(user => {
            //     user.name = user.firstName + ' '+user.lastName
            // })

            // this.assignees.forEach(a => {
            //     let index = this.storeUsers.findIndex(x => x.name == a)
            //     if (index != -1) {
            //         let i = list.findIndex(y => y == this.storeUsers[index].id)
            //         if (i == -1) {
            //             list.push(this.storeUsers[index].id)
            //         }
            //     }
            // })
            let sendObj = JSON.parse(JSON.stringify(this.editTask))
            sendObj.assignTo = list
            sendObj.dueDate = parseInt(this.moment(sendObj.dueDate).format('X'))*1000
            console.log('sendobj', sendObj)
            this.$api.put(`/tasks/${this.instance.id}/${this.editTask.id}`, sendObj). then(({data}) => {
                console.log('just updated', data)
                this.$store.commit('UPDATE_TASK', data)
                this.$emit('updatetable')
                this.assignees = []
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        closeEditModal(){
            this.$emit('cancelEdit')
            this.files = [],
            this.assignees = []
            this.newComment.content = ''
        },
        cancelComment(){
            this.newComment.content = ''
        },
        saveComment(comment){
            console.log('aaa', this.editTask.id)
            this.loading = true
            this.$api.put(`/tasks/${this.instance.id}/${this.editTask.id}/comment`, {content:comment}).then( ({data}) => {
                console.log('update comment', data)
                this.$store.commit('UPDATE_TASK', data)
                this.$emit('updatetable')
                this.loading = false
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        onChange(value, dateString){
            console.log('VALUEEEE', value)
            console.log('DATESTRINGGGG', dateString)
            let task = JSON.parse(JSON.stringify(this.editTask))
            if (value != null && value != ''){
                task.dueDate = parseInt(this.moment(dateString).format('X'))*1000
            } else {
                task.dueDate = 0
            }

            console.log('taskkkk', task)

            this.$api.put(`/tasks/${this.instance.id}/${task.id}`, task).then( ({data}) => {
                console.log('update task', data)
                this.$emit('updatetable')

                this.$store.commit('UPDATE_TASK', data)
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        searchAssign(input){
            if(input && input.length>1) {
                this.storeUsers.forEach(x => {
                    if (x != null && x.firstName && x.lastName)x.name = x.firstName+ ' ' + x.lastName
                })
                this.userSource = this.storeUsers.filter(({name}) => {
                    name = name.toLowerCase()
                    input = input.toLowerCase()
                    return name.includes(input)
                })
            } else {
                return this.userSource = []
            }
        },
        deleteComment(item) {
            let self = this
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this Comment?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.$api.delete(`/comments/${self.instance.id}/${item.id}`).then( ({data}) => {
                        console.log('delete comment', data)
                        self.$store.commit('DELETE_COMMENT', data)
                        self.$emit('updatetable')
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        selectPriority(item) {
            let task = JSON.parse(JSON.stringify(this.editTask))
            if (typeof(task.dueDate) == 'string') {
                task.dueDate = parseInt(this.moment(task.dueDate).format('X'))*1000
            }
            task.priority = item

            this.$api.put(`/tasks/${this.instance.id}/${task.id}`, task). then(({data}) => {
                console.log('just updated', data)
                this.$store.commit('UPDATE_TASK', data)
                this.$emit('updatetable')
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        selectFiles(item) {
            let files = []
            if (this.editTask.files) {
                this.editTask.files.forEach(x => {
                    files.push(x)
                })
				item.forEach(x => {
					files.push(x);
				})
            }
            else {
				item.forEach(x => {
					files.push(x);
				})
			}
			item.forEach(x => {
				this.files.push(x);
			})
            this.$api.put(`/tasks/${this.instance.id}/${this.editTask.id}`, {files}). then(({data}) => {
                console.log('just updated', data)
                this.$store.commit('UPDATE_TASK', data)
                this.$emit('updatetable')
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        editComment(item) {
            this.$emit('editItem', item)
        },
        updateText(){
            this.$emit('updatetable')
        }
    },
    mounted(){
        window.FileSaver = FileSaver
    }
}
</script>

<style scoped lang="scss">
.delete-button {
    &:hover {
        color:var(--orange);
    }
}
.replyBox {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
        border-top: 1px solid #d9d9d9;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        &:focus{
            border-color: #d9d9d9;
            border-right-width: 0;
            box-shadow: none;
            -webkit-box-shadow: none;
        }
        &:hover{
            border-color: #d9d9d9;
        }
    }
.popoverContent{
    height: 35px;
    // width: 100px;
    line-height: 35px;
    padding-left: 10px;
    padding-right:10px;
}
.popoverContent:hover{
    background-color: var(--off-white-light);
    cursor: pointer;
}
</style>
<style lang="scss">
.popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
</style>
