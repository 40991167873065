<template>
  <div>
    <editor-content class="editor__content" :editor="editor" />
    <div class="menu_buttons">
      <div v-if="editor" class="dF aC">
        <a-icon class="menubar-button" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }" type="bold" />
        <a-icon class="menubar-button" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }" type="italic" />
        <div class="menubar-button" style="font-size:16px" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
          H1
        </div>
        <div class="menubar-button" style="font-size:16px" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
          H2
        </div>
        <div class="menubar-button" style="font-size:16px" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
          H3
        </div>
        <a-icon class="menubar-button" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }" type="unordered-list" />
        <a-icon class="menubar-button" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }" type="ordered-list" />
      </div>
      <div v-if="updatedHTML != ''">
        <!-- <a-button class="mr-3 cancel-button text-center" style="width:120px; background-color:#ECE9F1; color:#3F3356" size="large">CANCEL</a-button> -->
        <a-button @click="submit" style="width:120px" type="primary" size="large">SAVE</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Mention from '@tiptap/extension-mention'
import MentionList from '@/components/contacts/MentionList'
import StarterKit from '@tiptap/starter-kit'

export default {
  props:{
    message:{
      default:'',
      type:String
    }
  },
  components: {
    EditorContent
  },
  data() {
    return {
      editor: null,
      limit: 280,
      updatedHTML:''
    }
  },
  methods:{
    submit() {
      console.log('submit', this.updatedHTML)
      this.$emit('save', this.updatedHTML)
      this.updatedHTML = ''
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        StarterKit,
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion: {
            items: query => {
              return this.users.filter(item => item.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
            },
            render: () => {
              let component
              let popup

              return {
                onStart: props => {
                  component = new VueRenderer(MentionList, {
                    parent: this,
                    propsData: props,
                  })

                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                  })
                },
                onUpdate(props) {
                  component.updateProps(props)
                  console.log('heillo', props)
                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                  })
                },
                onKeyDown(props) {
                  if (component.ref && component.ref.onKeyDown){
                    return component.ref.onKeyDown(props)
                  }
                },
                onExit() {
                  console.log('exit')
                  popup[0].destroy()
                  component.destroy()
                },
              }
            },
          },
        }),
      ],
      onUpdate: () => {
        this.updatedHTML = this.editor.view.dom.innerHTML
        console.log('update', this.editor)
      },
      onBlur:() =>{
        if (this.updatedHTML == null) {
            this.updatedHTML = ''
        } 
        console.log('HTML', this.updatedHTML)
        // this.$emit('save', this.updatedHTML)
      },
      content: this.message,
    })
  },

  computed: {
    users() {
      let users = []
      if (this.$store.state.contacts.users) {
        this.$store.state.contacts.users.forEach(user => {
          let item = {name:'', id:''}
          item.name = `${user.firstName} ${user.lastName}`
          item.id = user.id
          users.push(item.name)
        })
      }
      return users
    }
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
<style>
.cancel-button.ant-btn:hover {
    border-color:#ECE9F1 !important;
}
</style>
<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
}

.mention {
  color: var(--orange);
  background-color: var(--off-white-dark);
  border-radius: 0.3rem;
  padding: 0.1rem 0.3rem;
}

.character-count {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  color: #68CEF8;

  &--warning {
    color: #FB5151;
  }

  &__graph {
    margin-right: 0.5rem;
  }

  &__text {
    color: #868e96;
  }
}
</style>
<style lang="scss">
.task__comment .ProseMirror{
  
    border:1px solid #d9d9d9;
    padding: 4px 11px;
    // height:100px;
    border-radius:4px 4px 0px 0px;
    &:focus{

        background-color:white;
        border:1px var(--orange) solid !important;
    }
    &:hover{
        border:1px var(--orange) solid !important;
    }
}
.menu_buttons{
    display:flex;
    justify-content:space-between;
    background-color:var(--off-white-dark);
    padding:10px;
    border:1px solid #d9d9d9;
    border-radius:0px 0px 4px 4px;
}
.menubar-button {
    -webkit-text-stroke-width:thin;
    stroke: 2px;
    color:black;
    background:transparent;
    border:none;
    padding: .2rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer;
    &.is-active{
        color:var(--orange);
    }
}
</style>