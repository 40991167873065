<template>
    <div>
        <EditDrawer @update="updateTasks" />
        <a-card>
            <div class="dF jE" slot="title">
                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                    <div slot="title">Add New Task</div>
                    <a-icon @click="addTask" style="font-size:25px; color:var(--orange); cursor:pointer" type="plus-circle" />
                </a-tooltip>
            </div>
            <template v-if="tasks.length">
                <div :class="keyI != 0 ? 'mt-5':''" v-for="(key, keyI) in Object.keys(orderTasks)" :value="key+keyI" :key="key+keyI">
                    <div style="color:#212529; font-size:15px; font-weight:bold;">{{key.toUpperCase()}}</div>
                    <template v-if="orderTasks[key].length">
                        <div class="dF jSB mt-3" v-for="task in orderTasks[key]" :value="task.id" :key="task.id">
                            <div class="dF">
                                <a-checkbox :value="task.id" :checked="task.completed" @change="changeComplete" class="checkbox-circle mr-3" />
                                <div>
                                    <div style="color:var(--orange);">{{task.title}}</div>
                                    <div v-if="task.description && task.description != ''" style="color:#000">{{task.description}}</div>
                                    <div style="color:#9EA0A5">{{`Due Date: ${convertDate(task.dueDate)}`}}</div>
                                </div>
                            </div>
                            <div class="dF">
                            <div class="dF mr-3">
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                    <template slot="title">Quick View</template>
                                    <a-icon type="eye" @click="$store.commit('OPEN_PREVIEW_MODAL', {type:'task', object:task})" class="icon-button mr-2" />
                                </a-tooltip>
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                    <template slot="title">Edit Task</template>
                                    <svg @click="editTask(task.id)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2 icon-button"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                </a-tooltip>
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                    <template slot="title">Delete Task</template>
                                    <i style="color:var(--danger)" @click="deleteTask(task.id)" class="fe fe-trash-2" />
                                </a-tooltip>
                            </div>
                            <div class="dF">
                                <template v-for="(user,userI) in task.assignTo">
                                    <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''" style="cursor:pointer">
                                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                            <template slot="title">{{`${user.firstName} ${user.lastName}`}}</template>
                                                <a-avatar v-if="user && user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                                                <a-avatar v-else>{{user && user.firstName && user.lastName ? user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase() : ''}}</a-avatar>
                                        </a-tooltip>
                                    </div>
                                </template>
                            </div>
                        </div>
                        </div>
                    </template>
                    <div v-else>{{`You don't have any tasks ${key}`}}</div>
                </div>
            </template>
            <template v-else>This opportunity doesn't have any task...</template>
        </a-card>
    </div>
</template>

<script>
import EditDrawer from '@/components/contacts/EditDrawer'
export default {
    components:{EditDrawer},
    props:{
        id:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            update:0
        }
    },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        orderTasks() {
			let today = new Date().getDate()
			let month = new Date().getMonth()
			let year = new Date().getFullYear()
			let tasks = {}
			if (this.tasks.length) {
				tasks.overdue = this.tasks.filter(x => {
					if (x.dueDate && x.dueDate != 0 && new Date(x.dueDate).getDate() < today && new Date(x.dueDate).getMonth() <= month && new Date(x.dueDate).getFullYear() <= year) {
						return x
					}
				})
				tasks.today = this.tasks.filter(x => {
					if (x.dueDate && x.dueDate != 0 && new Date(x.dueDate).getDate() == today && new Date(x.dueDate).getMonth() == month && new Date(x.dueDate).getFullYear() == year) {
						return x
					}
				})
				tasks.tomorrow = this.tasks.filter(x => {
					if (x.dueDate && x.dueDate != 0 && new Date(x.dueDate).getDate() == today + 1 && new Date(x.dueDate).getMonth() == month && new Date(x.dueDate).getFullYear() == year) {
						return x
					}
				})
				let tempTask = [].concat(tasks.overdue, tasks.today, tasks.tomorrow)

				tasks.later = this.tasks.filter(task => {
					let index = tempTask.findIndex(t => t.id == task.id)
					return index == -1
				})
			}
			return tasks
		},
        storeTask() {
            return this.$store.state.contacts.tasks || []
        },
        tasks() {
            let tasks = []
            let time = this.update
            if (this.storeTask.length) {
                tasks = this.storeTask.filter(x => {
                    if (x.opportunity && x.opportunity.id && x.opportunity.id == this.id) {
                        return x
                    }
                })
            }
            return tasks
        }
    },
    methods:{
        changeComplete(e) {
            let find = this.storeTask.find(x => x.id == e.target.value)
            if (find) {
                let sendObj = JSON.parse(JSON.stringify(find))
                sendObj.completed = e.target.checked
                this.$api.put(`/tasks/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                    this.$store.commit('UPDATE_TASK', data)
                    this.update = Date.now()
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
        },
        deleteTask(id) {
            console.log('deletetask',id)
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete Tasks')
            }
            let self = this
            this.$confirm({
                title: "Delete Task",
                content: h => <div>Do you want to delete this task?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.loading = true
                    self.$api.delete(`/tasks/:instance/${id}`).then( ({data}) => {
                        console.log('DATAA COMING BACK', data)
                        self.loading = false
                        self.$store.commit('DELETE_TASK', data)
                    }).catch(err => {
						self.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        updateTasks() {
            this.update = Date.now()
        },
        editTask(id) {
            this.$store.commit('OPEN_EDIT_DRAWER', {type:'task', id:id})
            console.log('edittask',id)
        },
        addTask() {
            this.$store.commit('ADD_NEW', 'Task')
            this.$store.commit('ADD_OPPORTUNITY_ACTION', this.id)
        },
        convertDate(num){
            if (num != 0) {
                let x = new Date(num)
                //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
                let year = x.getFullYear()
                let month = x.getMonth()+1
                let day = x.getDate()
				if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
				else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
				else {
					return month+'/'+day+'/'+year
				}
            } else return 'N/A'
        },
    }
}
</script>
<style>
.checkbox-circle .ant-checkbox-inner{
    border-radius: 50%;
}
.checkbox-circle .ant-checkbox-checked::after {
    border:none;
}
</style>
<style scoped>
.icon-button{
    cursor: pointer;
}
.icon-button:hover{
    color:var(--orange)
}
</style>
