<template>
    <a-modal
        :visible="opportunity.visible"
        :footer="false"
        @cancel="onClose"
        @ok="onClose"
        :width="'800px'"
        :bodyStyle="{padding: '0'}"
        centered
        >

        <template>
            <div style="padding:30px 24px; border-radius: 4px 4px 0 0 " :style="opportunity.type == 'won' ? 'background-color:#1EC48C;': opportunity.type == 'lost' ? 'background-color:#FD6C83':''">
                <h4 :style="opportunity.type == 'lost' || opportunity.type == 'won' ? 'color:white' : '' " style="margin:0">{{opportunity.type === 'won'?'Won Opportunity' : opportunity.type === 'lost'?'Lost Opportunity':opportunity.type === "archive"?'Archive Opportunity' : ''}}</h4>
            </div>
            <div style="padding:0 24px 24px 24px">
                <a-form-item class="mt-4" v-if="opportunity.type === 'lost'" label="Add a loss reason">
                    <a-select @change="changeLost" v-model="opportunity.opportunity.reason" style="width: 100%;" size="large">
                        <div slot="dropdownRender" slot-scope="menu">
                            <v-nodes :vnodes="menu" />
                            <a-divider style="margin: 4px 0;" />
                            <div
                                style="padding: 8px; cursor: pointer; color:var(--orange)"
                                @mousedown="e => e.preventDefault()"
                                @click="$store.commit('OPEN_LEAD_STATUS', {type:'lossReason'})"
                            >
                                <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Reason
                            </div>
                        </div>
                        <a-select-option v-for="(reason,reasonI) in settings.options.lostReasons" :key="reason+reasonI" :value="reason.id">
                            {{reason.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item :class="opportunity.type == 'won' ? 'mt-4' : ''" v-if="opportunity.type === 'won' || opportunity.type === 'archive'" label="Would you like to add final notes?">
                    <a-input class="mb-3" size="large" v-model="note.subject" placeholder="Subject" />
                    <a-textarea
                        v-model="note.content"
                        :auto-size="{ minRows: 4, maxRows: 4 }"
                        placeholder="Add note"
                        />
                </a-form-item>

                <a-checkbox v-model="changeClose">
                    Change the close date to today
                </a-checkbox>

                <div class="mt-4" style="width: 100%; text-align: right;">
                    <a-button @click="onClose" size="large" class="mr-3 cancel-button" style="width:116px">CANCEL</a-button>
                    <a-button @click="submit" :class="opportunity.type == 'won' ? 'won-button' : opportunity.type == 'lost' ? 'lost-button' : ''" size="large" :type="opportunity.type == 'archive' ? 'primary' : ''" style="width:116px">SAVE</a-button>
                </div>
            </div>
        </template>
    </a-modal>
</template>

<script>
export default {
    components:{
        VNodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes,
        },
    },
    props: {
		opportunity:{
			default:{},
			type:Object
        },
    },
    data() {
        return {
            changeClose:false,
            note:{
                subject:'',
                content:''
            }
        }
    },
    watch:{
        
    },
    computed:{
        instance(){
            return this.$store.state.instance
        },
        settings(){
            return this.$store.state.contacts.allSettings.app
        }
    },
    methods:{
        changeLost(e) {
            console.log('e',e)
        },
        onClose(){
            console.log('ONCLOSE RAN')
            this.$emit('closed')
            this.note = {
                subject:'',
                content:'',
            }
            this.changeClose = false
        },
        submit(){
            console.log('SUBMIT RAN')
            if (this.opportunity.type == 'won'){
                if (this.changeClose){
                    this.opportunity.opportunity.closeDate = Date.now()
                }
                this.$emit('won',this.opportunity.opportunity, this.note)
                this.changeClose = false
            } else if (this.opportunity.type == 'lost'){
                if (this.changeClose){
                    this.opportunity.opportunity.closeDate = Date.now()
                }
                this.$emit('lost',this.opportunity.opportunity)
                this.changeClose = false
            } else {
                if (this.changeClose){
                    this.opportunity.opportunity.closeDate = Date.now()
                }
                this.$emit('archive',this.opportunity.opportunity, this.note)
                this.changeClose = false
                this.note = {
                    subject:'',
                    content:''
                }
            }
        },
    },
    created(){
        
    },
    mounted(){

    }
}
</script>

<style>
.won-button.ant-btn:hover {
    border-color:#1EC48C !important;
}
.lost-button.ant-btn:hover {
    border-color:#FD6C83 !important;
}
.won-button.ant-btn {
    border-color:#1EC48C !important;
    background-color:#1EC48C; 
    color:#FFF
}
.lost-button.ant-btn {
    border-color:#FD6C83 !important;
    background-color:#FD6C83; 
    color:#FFF
}
</style>