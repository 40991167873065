<template>
    <Observer @on-change="onObserved">
        <slot />
    </Observer>
</template>

<script>
import Observer from 'vue-intersection-observer'
export default {
    components: {
        Observer
    },
    methods:{
        onObserved(entry, unobserve){
            if (entry.isIntersecting) {
                unobserve()
                this.$emit('observed')
            }
        },
    }
}
</script>

<style>

</style>