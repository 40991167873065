<template>
    <!-- Expanded Details Contact Page -->
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="background-color: #F7F5F9; overflow: scroll;">
        <opportunityDetails :time="refresh" />
        <BHLoading :show="loading" />
        <!-- <EditTaskModal @cancelEdit="cancelEdit" />
        <EditNoteModal />
        <EditAppointmentModal @cancelEdit="cancelEditAppointment" @updated="updatedAppointment" /> -->
        <!-- <EditDrawer /> -->
        <div class="mb-3" style="display: inline-block;">
            <div style="display: inline-block; cursor: pointer;">
                <div @click="$router.push('/leads')" class="dF">
                    <a-icon class="mr-4" style="font-size: 1.75rem;" type="arrow-left" />
                    <!-- <h3 v-if="contact.firstName && contact.lastName && contact.firstName.trim() && contact.lastName.trim()">{{`${contact.firstName} ${contact.lastName}`}}</h3>
                    <h3 v-else>{{`${contact.email}`}}</h3> -->
					<h3>Back to All Leads</h3>
                </div>
            </div>
        </div>

        <div class="dF">
            <div style="width:325px">
                <a-card class="mb-4">
                    <div class="dF aC jC">
                        <a-avatar style="font-size: 30px; background-color:#1070CA" shape="circle" :size="70">{{contact.firstName && contact.lastName && contact.firstName.trim() && contact.lastName.trim() ? contact.firstName[0].toUpperCase() + contact.lastName[0].toUpperCase(): contact.email ? `${contact.email[0].toUpperCase()}` : ''}}</a-avatar>
                        <div class="ml-3">
                            <h5>{{contact.firstName && contact.lastName && contact.firstName.trim() && contact.lastName.trim() ? `${contact.firstName} ${contact.lastName}`:`${contact.email}`}}</h5>
                            <div style="color: #9EA0A5;" v-if="contact.jobTitle && contact.jobTitle != ''">{{contact.jobTitle}}</div>
                            <div v-if="contact.phone && contact.phone != ''" style="color: var(--orange);">{{displayPhone(contact.phone)}}</div>
                        </div>
                    </div>
                    <div class="mt-3 dF jC">
						<a-progress status="normal" :percent="+((contact.leadScore/$store.state.contacts.maxLeadScore) * 100).toFixed(2)" size="small" strokeColor="#f7941e">
							<template #format="percent">
								<span style="font-size: medium;">
									{{ contact.leadScore }}
								</span>
							</template>
						</a-progress>
                    </div>
                    <div class="dF jC aC mt-3" style="color:#9EA0A5">
                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">Email Lead</template>
                            <svg @click="emailContact" style="cursor:pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail mr-4"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                        </a-tooltip>
                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">Delete Lead</template>
                            <svg @click="deleteContact" style="cursor:pointer; color:var(--danger)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                        </a-tooltip>
                    </div>
                    <div class="dF jC aC mt-3">
                        <a-button @click="convertLead" class="px-5" type="primary">CONVERT LEAD</a-button>
                    </div>
                </a-card>
                <menuList :menuList="settingsMenu" @menuclick="showMenu" :defaultSelectedKeys="[activeTab]" />
            </div>
            <div class="f1 ml-4">
                <div style="display:grid; grid-template-columns: repeat(3, minmax(0, 1fr)); gap:20px;">
                    <div class="display-card">
                        <div style="color: rgb(102, 120, 138); font-size: 18px; font-weight: bold;">OPT-IN STATUS</div>
						<div class="dF aC mt-2">
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title" v-if="contact.bounced">
									Click to view bounce reason
								</template>
								<template slot="title" v-if="contact.unsub && contact.unsubReason">
									{{ contact.unsubReason }}
								</template>
								<a-tag v-if="contact.bounced" @click="viewBounceReason" class="py-1" style="cursor: pointer;" :color="contact.unsub || contact.bounced ? 'var(--danger)' : 'var(--success)'">{{ contact.unsub ? 'Unsubscribed' : contact.bounced ? 'Bounced' : 'Subscribed' }}</a-tag>
								<a-tag v-else class="py-1" :color="contact.unsub || contact.bounced ? 'var(--danger)' : 'var(--success)'">{{ contact.unsub ? 'Unsubscribed' : contact.bounced ? 'Bounced' : 'Subscribed' }}</a-tag>
							</a-tooltip>
							<a-button v-if="contact.bounced" type="primary" ghost @click="changeOptInStatus">
								Change to Subscribed
							</a-button>
						</div>
                    </div>
                    <div class="display-card">
                        <div style="color: rgb(102, 120, 138); font-size: 18px; font-weight: bold;">INACTIVE DAYS</div>
                        <div style="font-size: clamp(14px,1.5vw,24px); color: black;">{{contact.updatedAt ? getInactive(contact.updatedAt) : '0'}}</div>
                    </div>
                    <div class="display-card">
                        <div style="color: rgb(102, 120, 138); font-size: 18px; font-weight: bold;">
							LAST UPDATED DATE
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title">
									Last time lead was edited or updated.
								</template>
								<a-icon type="question-circle" class="ml-1" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
							</a-tooltip>
						</div>
                        <div style="font-size: clamp(14px,1.5vw,24px); color: black;">{{contact.updatedAt ? convertDate(contact.updatedAt) : '0'}}</div>
                    </div>
                </div>
                <div class="mt-4">
                    <InformationTab v-if="activeTab == 0" />
                    <LeadOpportunity :id="contact.id" v-if="activeTab == 1" />
                    <LeadNote @update="updateNotes" :id="contact.id" v-if="activeTab == 2" />
                    <LeadTask :id="contact.id" v-if="activeTab == 3" />
                    <LeadAppointment :id="contact.id" v-if="activeTab == 4" />
					<LeadEvent :id="contact.id" v-if="activeTab == 5" />
                    <LeadEmail :id="contact.id" :email="contact.email" v-if="activeTab == 6" />
                    <EmailTracking v-if="activeTab == 7" :contact="contact"/>
                    <LeadTransactions :id="contact.id" :showAll="true" v-if="activeTab == 8" />
                    <LeadForm :id="contact.id" v-if="activeTab == 9" />
                    <LeadAttachment @update="update" :id="contact.id" v-if="activeTab == 10" />
                    <LeadTimeline :id="contact.id" v-if="activeTab == 11" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import opportunityDetails from '@/components/common/opportunityDetails'
    import BHLoading from 'bh-mod/components/common/Loading'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    import menuList from '@/components/common/menuList'
    import EditTaskModal from '@/components/contacts/EditTaskModal'
    import EditNoteModal from '@/components/common/EditNoteModal'
    import EditAppointmentModal from '@/components/contacts/EditAppointmentModal'
    import InformationTab from '@/components/contacts/InformationTab'
    import LeadOpportunity from '@/components/contacts/LeadOpportunity'
    import LeadNote from '@/components/contacts/LeadNote'
    import LeadTask from '@/components/contacts/LeadTask'
    import LeadAppointment from '@/components/contacts/LeadAppointment'
	import LeadEvent from '@/components/contacts/LeadEvent'
    import LeadForm from '@/components/contacts/LeadForm'
    import LeadAttachment from '@/components/contacts/LeadAttachment'
    import LeadTransactions from '@/components/contacts/LeadTransactions'
    import LeadEmail from '@/components/contacts/LeadEmail'
    import EmailTracking from '@/components/contacts/EmailTracking'
    import LeadTimeline from '@/components/contacts/LeadTimeline'
    import moment from 'moment'

// import Index from '../layout/SubBar/index.vue'

    export default {
        components:{
            BHLoading,EmptyApp,menuList,EditTaskModal,EditNoteModal,EditAppointmentModal,InformationTab, LeadOpportunity,LeadNote,LeadTask,LeadAppointment,LeadEvent,LeadForm, LeadAttachment,LeadTimeline,LeadTransactions,opportunityDetails,LeadEmail, EmailTracking
        },
        data() {
            return {
                refresh:0,
                contact:{},
                activeTab:0,
                loading:false,
				events:[],
                forms:[],
                attachments:[],
                emailCount:0
            }
        },
        watch:{
            '$route':{
                immediate:true,
                async handler(val){
                    if (val.params && val.params.id && val.params.id != '') {
                        this.$store.commit('EDIT_CONTACT', val.params.id)
                        if (this.$store.state.contacts.allContacts[val.params.id]) this.contact = JSON.parse(JSON.stringify(this.$store.state.contacts.allContacts[val.params.id]))
                        else if (this.$store.state.contacts.externalContacts[val.params.id]) this.contact = JSON.parse(JSON.stringify(this.$store.state.contacts.externalContacts[val.params.id]))
                        else {
                            this.loading = true
                            await this.$api.get(`/contacts/:instance/${val.params.id}`).then( ({data}) => {
                                console.log('DATA COMING BACK FROM GET REQUEST', data)
                                this.$store.commit('ADD_EXTERNAL_CONTACT', data)
                                this.loading = false
                                this.contact = JSON.parse(JSON.stringify(this.$store.state.contacts.externalContacts[val.params.id]))
                            }).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                        console.log('contact', this.contact)
                        if (this.contact.notes && this.contact.notes.length){
                            this.loading = true
                            this.$api.get(`/contacts/:instance/${val.params.id}/notes`).then( ({data}) => {
                                console.log('DATA COMING BACK FROM GET REQUEST', data)
                                data.sort((a,b) => {
                                    return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                                })
                                this.loading = false
                                this.$store.commit('UPDATE_CONTACT_NOTES',{id:val.params.id, notes:data})
                            }).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})

                        }
                        this.loading = true
                        this.$api.get(`/form-submits/:instance?contact=${val.params.id}`).then(({data}) => {
                            this.forms = data
                            this.forms.forEach(form => {
                                if (form.data && form.data.fields) {
                                    let files = form.data.fields.filter(x => {
                                        if (x.field && x.field.type && x.field.type == 'file' && x.value) return x
                                    })

                                    console.log('files', files, this.forms)
                                    this.attachments = this.attachments.concat(files)
                                }
                            })
                            this.loading = false
                        }).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
						this.loading = true
                        this.$api.get(`/event-slots/:instance?contact=${val.params.id}`).then(({data}) => {
                            this.events = data
                            this.loading = false
                        })
						this.loading = true
						this.$api.get(`/contacts/:instance/inbox-count?contact=${this.contact.email}`).then(({ data }) => {
							this.emailCount = data
							this.loading = false
						}).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						}).finally(() => {this.loading = false});
                        if (this.allContacts[val.params.id] && this.allContacts[val.params.id].others && this.allContacts[val.params.id].others.attachments) {
                            this.attachments = this.attachments.concat(this.allContacts[val.params.id].others.attachments)
                        } else if (this.externalContacts[val.params.id] && this.externalContacts[val.params.id].others && this.externalContacts[val.params.id].others.attachments) {
                            this.attachments = this.attachments.concat(this.externalContacts[val.params.id].others.attachments)
                        }
                        this.$store.commit('SIDEBAR_SELECTION', {value:'Leads'})
                    }
                }
            },
        },
        computed:{
			dateFormat(){
				return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
			},
            countNotes() {
                let time = this.refresh
                if (this.allContacts[this.$route.params.id] && this.allContacts[this.$route.params.id].notes && this.allContacts[this.$route.params.id].notes.length) return this.allContacts[this.$route.params.id].notes.length
                else if (this.externalContacts[this.$route.params.id] && this.externalContacts[this.$route.params.id].notes && this.externalContacts[this.$route.params.id].notes.length) return this.externalContacts[this.$route.params.id].notes.length
                return 0
            },
            transactions() {
                let transactions = []
                if (this.$store.state.contacts.transactions) {
                    transactions = this.$store.state.contacts.transactions.filter(x => {
                        if (x.purchasers) {
                            let emails = x.purchasers.map(y => y = y.email)
                            if (emails.includes(this.contact.email)) return x
                        }
                    })
                }
                return transactions
            },
            allContacts() {
                return this.$store.state.contacts.allContacts
            },
            externalContacts() {
                return this.$store.state.contacts.externalContacts
            },
            opportunities() {
                let opp = []
                if (this.$store.state.contacts.opportunities) {
                    opp = this.$store.state.contacts.opportunities.filter(x => {
						if (x.contact && x.contact.id && x.contact.id == this.contact.id) return x
						if (x.secondaryContacts && x.secondaryContacts.length) {
							let ids = x.secondaryContacts.map(s => s.id)
							if (ids.includes(this.contact.id)) {
								return true
							}
						}
					})
                }
                return opp
            },
            tasks() {
                let tasks = []
                if (this.$store.state.contacts.tasks) {
                    tasks = this.$store.state.contacts.tasks.filter(x => {
                        if (x.contact && x.contact.id && x.contact.id == this.contact.id) return x
                    })
                }
                return tasks
            },
            appointments() {
                let app = []
                if (this.$store.state.contacts.appointments) {
                    app = this.$store.state.contacts.appointments.filter(x => {
                        if (x.contact && x.contact.id && x.contact.id == this.contact.id) return x
                    })
                }
                return app
            },
            count() {
                console.log('COUNTTT', this.$store.getters.count)
                console.log('TESTING', this.$store.state.contacts.externalContacts.hasOwnProperty(this.$store.getters.count))
                return this.$store.getters.count
            },
            settingsMenu(){
                let items = [
                    {label: 'General Information', ant: 'info-circle'},
                    {label: `Opportunities (${this.opportunities.length})`, fa:'money-bill-alt'},
                    {label: `Notes (${this.countNotes})`, fe: 'message'},
                    {label: `Tasks (${this.tasks.length})`, fe: 'user-check'},
                    {label: `Appointments (${this.appointments.length})`, ant: 'calendar'},
					{label: `Events (${this.events.length})`, ant: 'calendar'},
                    {label: `Email (${this.emailCount})`, fa: 'envelope'},
                    {label:`Marketing Email Tracking`, fa:'at'},
                    {label: `Transactions (${this.transactions.length})`, fa:'money-bill-alt'},
                    {label: `Form Submissions (${this.forms.length})`, fe: 'align-justify'},
                    {label: `Attachments (${this.attachments.length})`, ant: 'file'},
                    {label: 'Timeline', ant: 'clock-circle'},
                ]
                return items
            },
			nylasAccount() {
				return this.$store.state.contacts.nylasAccount || {}
			}
        },




        methods:{
			viewBounceReason(){
				this.loading = true;
				this.$api.get(`/contacts/:instance/${this.contact.id}/bouncereason`).then(({ data }) => {
					console.log('DATA COMING BACK FROM GET REQUEST', data)
					this.$message.info(data);
					this.loading = false;
				}).catch(err => {
					this.$message.error(this.$err(err))
				}).finally(() => {
					this.loading = false;
				})
			},
            updateNotes() {
                this.refresh = Date.now()
            },
            getInactive(updatedAt) {
                let today = moment(new Date())
                let update = moment(updatedAt)
                return Math.round(today.diff(update, 'days', true))
            },
            update(file) {
                this.attachments.push(file)
            },
            changeRate(e) {
                console.log('e', this.contact)
                let sendObj = JSON.parse(JSON.stringify(this.contact))
                sendObj.leadScore = e
                this.$store.commit('LOAD', true)
                this.$api.put(`/contacts/:instance/${sendObj.id}`,sendObj).then( ({data}) => {
                    this.$store.commit('SET_PROP', {where:['allContacts',data.id],what:data} )
                    this.contact.leadScore = e
                    this.$store.commit('LOAD', false)
                }).catch( err => {
                    this.$message.error(this.$err(err))
                })
            },
			changeOptInStatus() {
                this.$store.commit('LOAD', true)
                this.$api.put(`/contacts/:instance/${this.contact.id}/activate-opt-in`).then( ({data}) => {
					console.log('DATA COMING BACK', data)
					this.contact = JSON.parse(JSON.stringify(data))
                    this.$store.commit('SET_PROP', {where:['allContacts',data.id],what:data} )
                }).catch( err => {
                    this.$message.error(this.$err(err))
                }).finally(() => {
					this.$store.commit('LOAD', false)
				})
            },
            showMenu(item){
                this.activeTab = item
            },
            deleteContact(){
                let self = this
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete Lead')
                }
                this.$confirm({
                    title: 'Delete Lead',
                    content: h => <div>Do you want to delete <b>{this.contact.email}</b>? All information related to them will be lost.</div>,
                    okText: 'DELETE',
                    okType: 'danger',
                    cancelText: 'CANCEL',
                    centered: true,
                    onOk(){
                        console.log('ok')
                        console.log('DELETE CONTACT', self.contact)
                        self.tasks.forEach( task => {
                        if (task.relatedType == 'contact' && (task.contact && task.contact.id) == self.contact.id){
                            self.$store.commit('DELETE_TASK', task)
                        }
                    })
                    self.appointments.forEach( appointment => {
                        if (appointment.relatedType == 'contact' && appointment.contact.id == self.contact.id){
                            self.$store.commit('DELETE_APPOINTMENT', appointment)
                        }
                    })
                    self.opportunities.forEach( opp => {
                        if (opp.contact && opp.contact.id == self.contact.id){
                            self.$store.commit('DELETE_OPPORTUNITY', opp)
                        }
                    })
                        self.$api.delete(`/contacts/:instance/${self.contact.id}`).then( ({data}) => {
                            console.log('DATAAAAA COMING BACK', data)
                            self.$store.commit('SET_TOTAL_RESULT', self.totalContacts - 1)
                            if (self.contacts && self.contacts.length == 1 && self.currentPage != 1){
                                self.currentPage = self.currentPage - 1
                                self.$store.dispatch('DO_SEARCH')
                            } else {
                                self.$store.dispatch('DO_SEARCH')
                            }
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                        self.$router.push('/leads')
                    },
                    onCancel(){
                        console.log('CANCELED')
                    }
                })
            },
            emailContact() {
                if (this.contact.id && this.contact.id != '') {
					if (this.nylasAccount && this.nylasAccount.email_address){
						this.$store.commit('ADD_LEAD_ACTION', this.contact.id)
						this.$store.commit('ADD_NEW', 'Email')
					} else {
						this.$notification['error']({
							message: 'No Email Connection',
							description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
							duration: 5
						});
					}
                }
            },
            displayPhone(num){
				if(!num) return '';
                let phone = num.toString().trim("").replace(/[^a-zA-Z0-9 ]/g, '')
                let first = phone.substring(0,3)
                let middle = phone.substring(3,6)
                let last = phone.substring(6,10)

                return '('+first+') '+middle+' '+last
            },
            convertLead() {
                console.log('convertlead')
                this.$store.commit('ADD_NEW', 'Convert Opportunity')
                this.$store.commit('ADD_LEAD_ACTION', this.contact.id)
            },
            moment,
			convertDate(num){
                let x = new Date(num)
                //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
                let year = x.getFullYear()
                let month = x.getMonth()+1
                let day = x.getDate()
				if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
				else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
				else {
					return month+'/'+day+'/'+year
				}
            },
        },
		created(){
			console.log('Viewing Contact Details...', this.$route)
			if (this.$route.query && this.$route.query.tab == 'email'){
				this.activeTab = 6
			}
		}
    }
</script>

<style lang="scss" scoped>

    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:5%;
        }
    }
    .aB{
        align-items: baseline;
    }

    .edit-delete {
        padding: 9px 0;
		cursor: pointer;
		color: #9EA0A5;
		transition:color .1s ease-in;
	}
	.edit-delete:hover {
		color: orange;
		transition:color .1s ease-in;
	}
    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
        color: var(--orange) !important;
        cursor: pointer;
        background-color: var(--light-gray);
	}

    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .smallIcon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        padding: 7px 0;
    }


</style>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .card-headers .ant-card-body {
        padding: 15px 25px;
    }
    .taskParagraph p{
        margin-bottom: 0 !important;
    }
</style>

<style scoped>
    .display-card{
        border-radius: 3px;
        border-color:rgba(63,63,63,.15);
        -webkit-box-shadow: 0 1px 3px rgb(63 63 68 / 15%);
        border:none;
        background-color:#FFF;
        padding:24px;
    }
</style>
