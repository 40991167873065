<template>
  <div class="editor">
     <div v-if="!editMode" @click="editMode = true" v-html="text" />
    <editor-menu-bar v-if="editMode" :editor="editor" v-slot="{ commands, isActive, focused }">
      <div
        class="menubar is-hidden"
        :class="{ 'is-focused': focused }"
      >

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <i class="fe fe-bold" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <i class="fe fe-italic" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <a-icon type="strikethrough" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <i class="fe fe-underline" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
        >
          <i class="fe fe-code" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <i class="fa fa-paragraph" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <i class="fe fe-list" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <a-icon type="ordered-list" />
        </button>

        <!-- <button
          class="menubar__button"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
          <i class="fa fa-quote-right" />
        </button> -->

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
          <i class="fe fe-code" />
        </button>

      </div>
    </editor-menu-bar>

    <editor-content v-if="editMode" class="editor__content" :editor="editor" />
    <div v-if="editButton">
      <a-button class="mr-3" @click="editText">{{edit ? 'UPDATE DESCRIPTION' : 'EDIT DESCRIPTION'}}</a-button>
      <a-button @click="cancelEdit" v-if="edit">CANCEL</a-button>
    </div>

    <div v-else>
      <a-button v-if="edit">edit</a-button>
      <div class="dF">
        <p style="color:#818181" >Edited {{item.updatedAt.substring(0,10)}}</p>

        <a-popover trigger="click" overlayClassName="popoverStyle">
            <div slot="content">

                <div @click="editComment" class="popoverContent"><i class="fe fe-edit-2 mr-3" />Edit Comment</div>
                <div class="popoverContent"><i class="fe fe-trash-2 mr-3" />Delete Comment</div>


            </div>
            <i class="fe fe-more-horizontal ml-5" style="font-size:20px; cursor: pointer;" />
        </a-popover>

      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'
export default {
  props:{
    item:{
      default: () => {},
      type:Object,
    },
    text:{
      default:'',
      type:String,
    },
    type: {
      default:'description',

    },
    editButton:{
      default:false,
      type:Boolean,
    }
  },
  components: {
    EditorContent,
    EditorMenuBar,

  },
  data() {
    return {
      editMode:false,
      edit:false,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        content: `

          <div>
            ${this.text}
          </div>
        `,
      }),

    }
  },
  methods:{
    editComment(){
      if (!this.edit) {
        this.edit = true
        this.editor.commands.paragraph()
      } else {
        this.edit = false
        !this.editor.commands.paragraph
      }


    },
    moment,
    editText(){
      if (!this.edit) {
        this.edit = true
        this.editor.commands.paragraph()
      } else {
        this.edit = false
        !this.editor.commands.paragraph
        let task = JSON.parse(JSON.stringify(this.item))
        task.dueDate = parseInt(this.moment(task.dueDate).format('X'))*1000
        task.description = this.editor.view.dom.innerHTML
        this.$api.put(`/tasks/:instance/${task.id}`, task).then( ({data}) => {
            this.$store.commit('UPDATE_TASK', data)
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
      }
    },
    cancelEdit(){
      this.edit = false
      !this.editor.commands.paragraph
    },

  },
  beforeDestroy() {
    this.editor.destroy()
    this.$emit('cleartext')
  },
}
</script>

<style lang="scss" scoped>
  .popoverContent{
      height: 35px;
      // width: 100px;
      line-height: 35px;
      padding-left: 10px;
      padding-right:10px;
  }
  .popoverContent:hover{
      background-color: var(--off-white-light);
      cursor: pointer;
  }
  .menubar.is-focused {
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility .2s,opacity .2s;
    transition: visibility .2s,opacity .2s;
  }
  .menubar {

    transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }

    &.is-focused {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.2s, opacity 0.2s;
    }

    &__button {
      font-weight: bold;
      display: inline-flex;
      background: transparent;
      border: 0;
      color: black;
      padding: 0.2rem 0.5rem;
      margin-right: 0.2rem;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: rgba(black, 0.05);
      }

      &.is-active {
        background-color: rgba(black, 0.1);
      }
    }
  }

  .menubar__button {
    font-weight: 700;
    display: -webkit-inline-box;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #000;
    padding: .2rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer;
  }
</style>
