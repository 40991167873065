<template>
    <div>
        <BHLoading :show="loading" />
        <a-card>
            <template v-if="events.length">
                <div :class="evtI != 0 ? 'mt-5':''" v-for="(evt,evtI) in Object.keys(orderEvents)" :key="evt+evtI" :value="evt+evtI">
                    <div style="color:#212529; font-size:15px; font-weight:bold;">{{evt.toUpperCase()}}</div>
					<template v-if="orderEvents[evt].length">
						<div class="dF jSB mt-3" v-for="event in orderEvents[evt]" :value="event.id" :key="event.id">
							<div class="dF">
								<div class="smallIcon mr-3" style="background-color: #FFDEAD;">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#F79425" stroke="#FFF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
								</div>
								<div>
									<div style="color:var(--orange);">{{event.eventGroup.title}}</div>
									<div style="color:#9EA0A5">{{evt == 'later' ? `${convertDate(event.startTime)} | ${getHour(event.startTime, event.endTime)}`:getHour(event.startTime, event.endTime)}}</div>
									<div class="dF" v-if="event && event.eventGroup && event.eventGroup.assignTo">
										<div>
											<div>Assigned to: {{`${getUser(event.eventGroup.assignTo)}`}}</div>
										</div>
									</div>
								</div>
							</div>
							<div>
								<a-tooltip slot="extra" overlayClassName="change-tooltip-color" class="mr-2">
									<template slot="title">View event</template>
									<a-icon @click="viewEvent(event)" style="font-size:16px; cursor:pointer" type="eye" class="action-icons" />
								</a-tooltip>
							</div>
						</div>
					</template>
					<div v-else>{{`You don't have any events ${evt}`}}</div>
                </div>
            </template>
            <template v-else>This lead doesn't have any events...</template>
        </a-card>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
export default {
    components:{BHLoading},
    props:{
        id:{
            type:String,
            default:''
        },
        contact:{
            type:Object,
            default: () => {}
        }
    },
    data() {
        return{
            events:[],
            loading:false,
        }
    },
	computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
		orderEvents() {
            let today = new Date();
			today.setHours(0, 0, 0, 0);

			let tomorrow = new Date();
			tomorrow.setDate(tomorrow.getDate() + 1);
			tomorrow.setHours(0, 0, 0, 0);

            let events = {}
            if (this.events.length) {
                events.today = this.events.filter(x => {
					let date = new Date(x.startTime)
                    if (date >= today && date < new Date(today.getTime() + (24 * 60 * 60 * 1000))) {
                        return x
                    }
                })
                events.tomorrow = this.events.filter(x => {
					let date = new Date(x.startTime)
                    if (date >= tomorrow && date < new Date(tomorrow.getTime() + (24 * 60 * 60 * 1000))) {
                        return x
                    }
                })
                events.later = this.events.filter(x => {
					let date = new Date(x.startTime)
                    if (date >= new Date(today.getTime() + (24 * 60 * 60 * 1000)) && !(date >= tomorrow && date < new Date(tomorrow.getTime() + (24 * 60 * 60 * 1000)))) {
                        return x
                    }
                })
				events.completed = this.events.filter(x => {
					let date = new Date(x.startTime)
                    if (today > date) {
                        return x
                    }
                })
            }
            console.log('events', events)
            return events
        }
	},
    methods:{
		viewEvent(event){
			console.log('Viewing Event...', event)
			this.$router.push(`/events/${event.eventGroup.id}`)
		},
		getUser(id) {
            if (id == this.$store.state.user.user.id) {
                return 'You'
            } else {
                let find = this.$store.state.contacts.users.find(x => x.id == id)
                if (find) return `${find.firstName} ${find.lastName}`
                else return 'N/A'
            }
        },
		getHour(start, end) {
            let startTime = new Date(start)
            let endTime = new Date(end)
			return `${startTime.getHours()}:${startTime.getMinutes() < 10 ? '0'+startTime.getMinutes() : startTime.getMinutes()} - ${endTime.getHours()}:${endTime.getMinutes() < 10 ? '0'+endTime.getMinutes() : endTime.getMinutes()}`
        },
		convertDate(num){
            if (num != 0) {
                let x = new Date(num)
                //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
                let year = x.getFullYear()
                let month = x.getMonth()+1
                let day = x.getDate()
				if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
				else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
				else {
					return month+'/'+day+'/'+year
				}
            } else return 'N/A'
        },
        getDateString(string) {
            if (string != '' && string != undefined) {
                let year = string.substring(0,4)
                let month = string.substring(5,7)
                let day = string.substring(8,10)
				if (this.dateFormat == 'YYYY/MM/DD') return `${year}/${month}/${day}`
				else if (this.dateFormat == 'DD/MM/YYYY') return `${day}/${month}/${year}`
				else {
					return `${month}/${day}/${year}`
				}
            } else {return ''}
        },
    },
    created() {
        if (this.id != '') {
            this.loading = true
            this.$api.get(`/event-slots/:instance?contact=${this.id}`).then(({data}) => {
                this.events = data
                this.loading = false
            })
        }
    }
}
</script>
<style scoped>
.action-icons:hover{
    color:var(--orange)
}
.headerLabel{
    color:#000;
}
</style>
<style>
.smallIcon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    padding: 7px 0;
}
</style>
