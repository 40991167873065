<template>
    <div>
        <BHLoading :show="loading" />
        <a-card>
            <!-- <div class="dF jE" slot="title">
                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                    <div slot="title">Add New Transaction</div>
                    <a :href="`https://transactions.bildhive.${$tld}/new?c=${this.contactId}&o=${$route.params.id}`" target="_blank"><a-icon style="font-size:25px; color:var(--orange); cursor:pointer" type="plus-circle" /></a>
                </a-tooltip>
            </div> -->
            <template v-if="transactions.length">
                <div class="dF jSB aS" :class="showAll ? 'list-transactions' : ''" v-for="trans in transactions" :key="trans.id" :value="trans.id">
                    <div class="dF aS">
                        <div>
                            <h3 v-if="!showAll">Transaction Details</h3>
                            <div style="color:black; font-size:15px">{{transactionName(trans)}}</div>
                            <div style="color:black">{{`ID: ${trans.id}`}}</div>
                            <div style="color:var(--orange)">{{`Purchaser(s): ${transactionPurchasers(trans)}`}}</div>
                            <div style="color:#9EA0A5">{{transactionDate(trans.createdAt)}}</div>
                        </div>
                    </div>
                    <div class="dF aC">
                        <i style="font-size:18px" class="fa fa-user mr-2" />
                        <div class="mr-3" v-if="trans.purchasers && trans.purchasers.length">{{trans.purchasers.length}}</div>
                        <div class="mr-3" v-for="(user,userI) in trans.owners" :key="user.id" :value="user.id">
                            <div v-if="userI < 3" :key="userI" class="owner-icon dF aC jC" :style="userI !== 0? 'margin-left: -5px' : ''" style="cursor:pointer;">
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                    <template slot="title">{{`${user.firstName} ${user.lastName}`}}</template>
                                    <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="20"/>
                                    <a-avatar :size="20" v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                                </a-tooltip>
                            </div>
                        </div>
                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">View Transaction</template>
                            <a target="_blank" :href="`https://transactions.bildhive.${$tld}/transaction/${trans.id}`">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                            </a>
                        </a-tooltip>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="dF jSB aC">
                    <h3 style="margin-bottom: 0;">No Transaction</h3>
                    <a-button type="primary" size="large">
                        <a :href="transactionURL" target="_blank">
                            Process Transaction
                        </a>
                    </a-button>
                </div>
            </template>
        </a-card>
    </div>
</template>

<script>
import {formatDate} from 'bh-mod'
import BHLoading from 'bh-mod/components/common/Loading'
export default {
    components:{
        BHLoading
    },
    props:{
        id:{
            default:'',
            type:String
        },
        contact:{
            default: () => ({}),
            type:Object
        },
		opportunity:{
            default: () => ({}),
            type:Object
        },
		showAll:{
			default:false,
			type:Boolean
		}
    },
    data() {
        return{
            contactId:'',
            loading:false,
			currentContact: {}
        }
    },
    computed:{
        allContacts() {
            return this.$store.state.contacts.allContacts
        },
        externalContacts() {
            return this.$store.state.contacts.externalContacts
        },
        transactions() {
			if (this.showAll){
				let contact = this.currentContact
				let transactions = []
				if (this.$store.state.contacts.transactions) {
					transactions = this.$store.state.contacts.transactions.filter(x => {
						if (x.purchasers) {
							let emails = x.purchasers.map(y => y = y.email)
							if (emails.includes(contact.email)) return x
						}
					})
				}
				return transactions
			} else {
				let transactions = []
				if (this.$store.state.contacts.transactions) {
					transactions = this.$store.state.contacts.transactions.filter(x => {
						if (x.opportunity) {
							if (x.opportunity == this.$route.params.id) return true
							else return false
						}
					})
				}
				return transactions
			}
        },
        instance() {
            return this.$store.state.instance
        },
		transactionURL() {
			let url = `https://transactions.bildhive.${this.$tld}/new?c=${this.contactId}`
			return `${url}${this.opportunity && this.opportunity.id ? '&o=' + this.opportunity.id : ''}`
        }
    },
    methods:{
        formatDate,
        transactionDate(tr){
            return formatDate(tr)
        },
        transactionPurchasers(tr){
            if (!tr.purchasers ) return 'No Purchaser(s)'
            if (!tr.purchasers.length) return 'No Purchaser(s)'
            return tr.purchasers.length < 3 ? tr.purchasers.filter( (x,xI) => xI < 2).map(x => `${x.firstName} ${x.lastName[0]}.`).join(', ') : `${tr.purchasers.length} Purchasers`
        },
        transactionName(tr){
            if (this.instance.productType == 'highrise'){
                let {product = {}, purchasers = [] } = tr
                let {floor = {}} = product
                let name = `${floor.name}`

                if (purchasers.length === 1) name += ` - (Buyer: ${purchasers[0].fullName})`
                else name += ` - ${purchasers.length} Purchasers`

                return name
            } else {
                let {product = {}, purchasers = [] } = tr
                let {lot = {}} = product
                let name = `Lot ${lot.name}`
                if (lot.address){
                    name += ` - ${lot.address}`
                } else {
                    if (purchasers.length === 1) name += ` - (Buyer: ${purchasers[0].fullName})`
                    else name += ` - ${purchasers.length} Purchasers`

                }
                return name
            }
        },
    },
	created() {
		if (this.id != '') {
			this.contactId = this.id
			if (this.allContacts[this.id]) this.currentContact = this.allContacts[this.id]
			else if (this.externalContacts[this.id]) this.currentContact = this.externalContacts[this.id]
		} else if (this.id == '' && Object.keys(this.contact).length != 0) {
			this.currentContact = this.contact
			this.contactId = this.contact.id
		}
    }
}
</script>

<style scoped>
.list-transactions:not(:first-child){
	margin-top: 2em;
}
.smallIcon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content:center;
    /* padding: 7px 0; */
}
.icon-button:hover{
    color:var(--orange);
}
</style>
