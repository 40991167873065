<template>
    <div>
        <a-card>
            <div v-for="(date,dateI) in createDates" :key="date+dateI">
                <h6 class="mb-4">{{textDate(date.date)}}</h6>
                <a-timeline>
                    <div class="dF" v-for="(d,i) in date.sorted" :key="d+i">
                        <div class="mr-3" style="margin-top: -5px; color: #9EA0A5;">
                            {{textTime(d)}}
                            <!-- {{d.date}} -->
                        </div>
                        <a-timeline-item>{{d.logNote}}</a-timeline-item>
                    </div>
                </a-timeline>
            </div>
        </a-card>
    </div>
</template>

<script>
import moment from 'moment'
import 'moment-timezone'
export default {
    props:{
        id:{
            type:String,
            default:''
        }
    },
    data() {
        return{
            contacts:{}
        }
    },
    computed:{
		timezone(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeZone && typeof this.$store.state.contacts.allSettings.user.options.regional.timeZone == 'string' ? this.$store.state.contacts.allSettings.user.options.regional.timeZone : 'America/New_York'
		},
		timeFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeFormat ? this.$store.state.contacts.allSettings.user.options.regional.timeFormat : 12
		},
        tasks(){
            let time = this.updateTime
            console.log('STORE TASKS', this.$store.state.contacts.tasks)
            let tasks = JSON.parse(JSON.stringify(this.$store.state.contacts.tasks))
            if (tasks && tasks.length) tasks = tasks.filter( x => x.relatedType == 'contact' && x.contact && this.contact && x.contact.id == this.contact.id)
            this.taskNum = tasks.length

            if (tasks && tasks.length){
                tasks.sort((a,b) => {
                    return a.dueDate - b.dueDate
                })
            }
            return tasks
        },
        appointments(){
            let time = this.refresh
            console.log('STORE APPOINTMENTS', this.$store.state.contacts.appointments)
            let appointments = JSON.parse(JSON.stringify(this.$store.state.contacts.appointments))
            if (appointments && appointments.length) appointments = appointments.filter( x => x.relatedType == 'contact' && x.contact.id == this.contact.id)
            this.appointmentNum = appointments.length

            if (appointments && appointments.length){
                appointments.sort((a,b) => {
                    return a.date - b.date
                })
            }
            return appointments
        },
        createDates(){
            let dates = {}
            this.events.forEach( x => {
                // console.log('X FROM EVENTS', new Date(x.date))
                let xDate = new Date(x.date)
                let month = xDate.getMonth() + 1
                let day = xDate.getDate()
                let year = xDate.getFullYear()
                // console.log('HAS OWN PROPERTY', `${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}`)
                if (!dates.hasOwnProperty(`${month}` + '/' + `${day}` + `/` + `${year}`)){
                    let obj = {
                        date:`${month}` + '/' + `${day}` + `/` + `${year}`,
                        sorted:this.sortDates(`${month}` + '/' + `${day}` + `/` + `${year}`)
                    }
                    dates[obj.date] = obj
                }
            })
            console.log('ALL DATES', dates)
            let sorted = Object.values(dates).sort( (a,b) => {
                let {date:date1} = a
                let {date:date2} = b

                date1 = new Date(date1).getTime()
                date2 = new Date(date2).getTime()

                return date1 > date2 ? -1 : 1
            })
            console.log('SORTED DATESSSS', sorted)

            return sorted
        },
        events(){
            let events = []
            if (this.contact && this.contact.id) {
                if (this.contact.updatedAt && this.contact.createdAt && parseInt(this.moment(this.contact.updatedAt).format('X')*1000) == parseInt(this.moment(this.contact.createdAt).format('X')*1000)) {
                    let obj = {}
                    obj.date = this.contact.createdAt
                    obj.logNote = 'This contact was added'
                    events.push(obj)
                } else if (this.contact.updatedAt && this.contact.createdAt && parseInt(this.moment(this.contact.updatedAt).format('X')*1000) != parseInt(this.moment(this.contact.createdAt).format('X')*1000)){
                    let created = {}
                    created.date = this.contact.createdAt
                    created.logNote = 'This contact was added'
                    let updated = {}
                    updated.date = this.contact.updatedAt
                    updated.logNote = 'This contact was updated'
                    events.push(created)
                    events.push(updated)
                }
                this.tasks.forEach(task => {
                    if (task.contact && (task.contact.id == this.contact.id || task.contact == this.contact.id)) {
                        let obj = {}
                        obj.date = task.createdAt
                        obj.logNote = 'A new task was added for this contact'
                        events.push(obj)
                    }
                })
                if (this.contact.notes && this.contact.notes.length) {
                    this.contact.notes.forEach(note => {
                        let obj = {}
                        obj.date = note.createdAt
                        obj.logNote = 'A new note was added for this contact'
                        events.push(obj)
                    })
                }
				if (this.contact.unsub && this.contact.unsubDate){
					let obj = {}
					obj.date = new Date(parseInt(this.contact.unsubDate))
					obj.logNote = 'This contact was unsubscribed'
					events.push(obj)
				}
                console.log('events', events)
            }
            return events
        },
    },
    methods:{
        moment,
        textTime(date){
            let x = new Date(date.date)
			x.setTime(x.getTime() + this.offsetDiffMs('get'));
            let hours = x.getHours()
            let minutes = x.getMinutes()
			minutes = minutes < 10 ? '0'+minutes : minutes

			let strTime = hours + ':' + minutes

			if (this.timeFormat == 12){
				let ampm = hours >= 12 ? 'PM' : 'AM'
				hours = hours % 12
				hours = hours ? hours : 12 // the hour '0' should be '12'
				strTime = hours + ':' + minutes + ' ' + ampm
			}

            return strTime
        },
		offsetDiffMs(type){
			let desiredTimezone = moment.tz(this.timezone);
			let desiredOffset = desiredTimezone.utcOffset();
			let currentOffset = new Date().getTimezoneOffset() * -1;
			let offsetDiff = 0
			let offsetDiffMs = 0
			if (currentOffset !== desiredOffset){
				if (type == 'set'){
					offsetDiff = currentOffset - desiredOffset;
				} else {
					offsetDiff = desiredOffset - currentOffset;
				}
				offsetDiffMs = offsetDiff * 60 * 1000;
			}
			return offsetDiffMs
		},
        textDate(date){
			const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let newDate = new Date(date)
			let month = monthNames[Number(newDate.getMonth())]
			return month + ' ' + newDate.getDate() + ', ' + newDate.getFullYear()
        },
        sortDates(date){
            let dates = []
            this.events.forEach( x => {
                let xDate = new Date(x.date)
                let month = xDate.getMonth() + 1
                let day = xDate.getDate()
                let year = xDate.getFullYear()
                if (date == (`${month}` + '/' + `${day}` + `/` + `${year}`)){
                    dates.push(x)
                }
            })
            dates.sort((a, b) => {
                return new Date(b.date) - new Date(a.date)
            })
            return dates
        },
    },
    created() {
        if (this.id != '' && this.$store.state.contacts.allContacts[this.id]) {
            this.contact = this.$store.state.contacts.allContacts[this.id]
        } else if (this.id != '' && this.$store.state.contacts.externalContacts[this.id]) {
            this.contact = this.$store.state.contacts.externalContacts[this.id]
        }
    }
}
</script>

<style>

</style>
