<template>
    <div>
        <bhLoading :show="loading" />
        <a-modal :centered="true" :footer="null" @cancel="previewModal.visible = false" :visible="previewModal.visible">
            <h5>Email Preview</h5>
            <hr />
            <div v-html="previewModal.broadcast.prodHtml"></div>
        </a-modal>
        <div style="padding:20px 0; background-color:#FFF;">
            <a-table :rowKey="(e) => e.id" class="white-table" :columns="columns" :pagination="false" :dataSource="source">
                <div slot="subject" slot-scope="obj">
                    <div>{{obj.broadcastName}}</div>
                </div>
                <div slot="date" slot-scope="obj">
                    <div>{{convertDate(obj.published_at)}}</div>
                </div>
                <div slot="totalOpens" slot-scope="obj">
                    <div>{{obj.totalOpens}}</div>
                </div>
                <div slot="clicks" slot-scope="obj">
                    <div>{{obj.totalClicks}}</div>
                </div>
                <div slot="actions" slot-scope="obj">
                    <div class="dF aC">
                        <a-popover trigger="click">
                            <div slot="content">
                                <template v-if="obj.clicked && Object.keys(obj.clicked).length">
                                    <div v-for="(link,linkI) in Object.keys(obj.clicked)" :key="link+linkI"><a :href="link" target="_blank"><b>{{link}}</b></a> - {{obj.clicked[link]}} Times</div>
                                </template>
                                <template v-else>
                                    No clicked links
                                </template>
                            </div>
                            <a class="link">Clicked Links</a>
                        </a-popover>

                        <div @click="viewEmail(obj.broadcastId)" class="ml-5 py-1 px-4" style="cursor: pointer; border:1px solid var(--orange); color:var(--orange); border-radius:4px">View</div>
                    </div>
                </div>
            </a-table>
        </div>
    </div>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
export default {
    components:{
        bhLoading
    },
    props:{
        contact:{
            type:Object,
            default: () => {}
        }
    },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
    },
    data() {
        return{
            previewModal:{
                visible:false,
                broadcast:{}
            },
            source:[],
            loading:false,
            columns:[
                {
                    title:'Email Subject',
                    key: 'subject',
                    scopedSlots: { customRender: 'subject' },
                },
                {
                    title:'Date Sent',
                    key: 'date',
                    scopedSlots: { customRender: 'date' },
                },
                {
                    title:'Opened',
                    key: 'totalOpens',
                    scopedSlots: { customRender: 'totalOpens' },
                },
                {
                    title:'Clicked',
                    key: 'clicks',
                    scopedSlots: { customRender: 'clicks' },
                },
                {
                    title:'',
                    key: 'actions',
                    scopedSlots: { customRender: 'actions' },
                },
            ]
        }
    },
    methods:{
        viewEmail(id) {
            console.log('hihia')
            this.$api.get(`/broadcasts/:instance/${id}`).then(({data}) => {
                this.previewModal.broadcast = data
                this.previewModal.visible = true
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
    },
    created() {
        if (this.contact && this.contact.email) {
            this.loading = true

            this.$api.post(`/contacts/:instance/events`, {contact:this.contact.email}).then(({data}) => {
                console.log('data', data)
                let events = []

                data.forEach(event => {
                    let item = {}
                    item = event
                    if (!item.hasOwnProperty('status') || !item.status) item.status = ''
                    if (!item.hasOwnProperty('name') || !item.name) item.name = ''

                    events.push(item)
                })

                this.source = events
                this.loading = false
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        }
    }
}
</script>

<style scoped>
.link{
    color:var(--orange);
}
.link:hover{
    text-decoration: underline;
    color:var(--orange);
}
</style>
